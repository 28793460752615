import {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';
import Img from '../../Img/Img';
import IconButton from '../../IconButton/IconButton';
import { IElementsListItem } from '../interfaces/IElementsListItem';
import { IDropDownMenuOption } from '../../DropDownMenu/interfaces/IDropDownMenu';
import { checkIsFolder } from '../../../utils/projectUtils';
import { ElementMenuActionTypes, ElementTypes, ProjectViewTargets } from '../../../constants/defines';
import styles from '../styles/ElementsListItem.module.scss';
import { getElementStyle } from '../../../utils/getStyles';
import { cn } from '../../../utils/general';
import { IS_IN_DVELOPMENT } from '../../../constants/general';
import FolderIcon from '../../../assets/folder.svg';
import PlusIcon from '../../../assets/plus.svg';
import DropIcon from '../../../assets/drop.svg';
import ErrorIcon from '../../../assets/error.svg';

const ElementsListItem: FC<IElementsListItem> = ({
  onChangeTarget,
  data,
  onOpenFolder,
  provided,
  snapshot,
  onAddElementToProject,
  menuAction,
  isCombineStatus,
  isVisibleAddButton,
  isCutOutElement,
}) => {
  const { t } = useTranslation();
  const changeTarget = useCallback(() => {
    onChangeTarget(data.type === ElementTypes.FOLDER ? ProjectViewTargets.FOLDER : ProjectViewTargets.ELEMENT);
  }, [data, onChangeTarget]);

  const openFolder = useCallback(() => {
    onOpenFolder({
      id: data.id,
      name: data.name,
      itHasChildren: data.itHasChildren,
    });
  }, [onOpenFolder, data]);

  const addElementToProject = useCallback(() => {
    onAddElementToProject(data);
  }, [onAddElementToProject, data]);

  const menuOptions = useMemo<IDropDownMenuOption[]>(() => [
    ...(!checkIsFolder(data) && !IS_IN_DVELOPMENT ? [
      {
        id: 'copy',
        title: t('copy'),
        onPress: () => {
          menuAction(ElementMenuActionTypes.COPY, data);
        },
      },
      {
        id: 'duplicate',
        title: t('duplicate'),
        onPress: () => {
          menuAction(ElementMenuActionTypes.DUPLICATE, data);
        },
      },
    ] : []),
    {
      id: 'cut',
      title: t('cut'),
      onPress: () => {
        menuAction(ElementMenuActionTypes.CUT, data);
      },
      isDisabled: isCutOutElement,
    },
    {
      id: 'edit',
      title: t('edit'),
      onPress: () => {
        menuAction(ElementMenuActionTypes.EDIT, data);
      },
    },
    {
      id: 'delete',
      title: t('delete'),
      onPress: () => {
        menuAction(ElementMenuActionTypes.DELETE, data);
      },
    },
  ], [t, data, menuAction, isCutOutElement]);

  return (
    <div
      ref={provided.innerRef}
      data-rbd-draggable-context-id={provided.draggableProps['data-rbd-draggable-context-id']}
      data-rbd-draggable-id={provided.draggableProps['data-rbd-draggable-id']}
      onTransitionEnd={provided.draggableProps.onTransitionEnd}
      aria-describedby={provided.dragHandleProps?.['aria-describedby']}
      data-rbd-drag-handle-context-id={provided.dragHandleProps?.['data-rbd-drag-handle-context-id']}
      data-rbd-drag-handle-draggable-id={provided.dragHandleProps?.['data-rbd-drag-handle-draggable-id']}
      draggable={provided.dragHandleProps?.draggable}
      onDragStart={provided.dragHandleProps?.onDragStart}
      role="button"
      tabIndex={provided.dragHandleProps?.tabIndex}
      onMouseDown={changeTarget}
      onTouchStart={changeTarget}
      onDoubleClick={checkIsFolder(data) ? openFolder : undefined}
      style={getElementStyle(snapshot, provided.draggableProps.style, isCutOutElement)}
      className={styles.container}
    >
      {snapshot.combineWith && (
        <div className={cn(styles.statusIconContainer, !isCombineStatus && styles.errorStatus)}>
          {isCombineStatus ? (
            <Img src={DropIcon} size={12} />
          ) : (
            <Img src={ErrorIcon} size={12} />
          )}
        </div>

      )}
      <div className={styles.content}>
        <span className={styles.name}>
          {data.name}
        </span>
        {!checkIsFolder(data) && (
          <span className={styles.value}>
            {`${data.value || 0} h`}
          </span>
        )}
      </div>
      {checkIsFolder(data) && (
        <IconButton icon={FolderIcon} size={10} onPress={openFolder} withFill />
      )}
      {isVisibleAddButton && !checkIsFolder(data) && (
        <IconButton icon={PlusIcon} size={10} onPress={addElementToProject} withFill />
      )}
      <DropDownMenu actions={menuOptions} />
    </div>
  );
};

export default memo(ElementsListItem);

import { FC, memo } from 'react';
import { IButton } from './interfaces/IButton';
import { cn } from '../../utils/general';
import styles from './styles/Button.module.scss';

const IconButton: FC<IButton> = ({
  title,
  onPress,
  customClass,
  disabled,
  isSubmit,
}) => {
  return (
    <button
      onClick={isSubmit ? undefined : (() => onPress())}
      className={cn(styles.container, customClass)}
      disabled={disabled}
      type={isSubmit ? 'submit' : 'button'}
    >
      <span>{title}</span>
    </button>
  );
};

export default memo(IconButton);

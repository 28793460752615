import React, { FC, memo } from 'react';
import Img from '../Img/Img';
import ILoader from './interfaces/ILoader';
import { cn } from '../../utils/general';
import styles from './sass/Loader.module.scss';
import LoaderIcon from '../../assets/loader.svg';

const Loader: FC<ILoader> = ({
  showAnimationDuration = 0, size = 80, customClass,
}) => (
  <div
    className={cn(styles.loaderContainer, showAnimationDuration > 0 && styles.fadeIn, customClass)}
    style={{ animationDuration: `${showAnimationDuration > 0 ? showAnimationDuration : 0}ms` }}
  >
    <Img src={LoaderIcon} size={size} />
  </div>
);

export default memo(Loader);

import { FC, memo } from 'react';
import ModalWindow from '../ModalWindow/ModalWindow';
import ElementForm from './components/ElementForm';
import { IModalElementForm } from './interfaces/IModalElementForm';

const ModalElementForm: FC<IModalElementForm> = ({
  onClose, onSubmit, isVisible, title, initValues, isProjectElement, multiplier,
}) => (
  <ModalWindow title={title} onClose={onClose} isVisible={isVisible}>
    <ElementForm
      onSubmit={onSubmit}
      initValues={initValues}
      isProjectElement={isProjectElement}
      multiplier={multiplier}
    />
  </ModalWindow>
);

export default memo(ModalElementForm);

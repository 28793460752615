import {
  FC, memo, useEffect, useRef, useState,
} from 'react';
import { usePopper } from 'react-popper';
import Img from '../Img/Img';
import { IDropDownMenu } from './interfaces/IDropDownMenu';
import { cn } from '../../utils/general';
import styles from './styles/DropDownMenu.module.scss';
import DotMenuIcon from '../../assets/dotMenu.svg';

const DropDownMenu: FC<IDropDownMenu> = ({
  actions, customClass, title, iconSrc, disabled,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (disabled) {
      setVisible(false);
    }
  }, [disabled]);

  const { styles: popperStyles, forceUpdate } = usePopper(ref.current, containerRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 2],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      {
        name: 'eventListeners',
        options: {
          resize: isVisible,
          scroll: isVisible,
        },
      },
    ],
  });

  useEffect(() => {
    const closeDropDown = (event: HTMLElementEventMap['click'] | TouchEvent) => {
      if (forceUpdate && isVisible) {
        forceUpdate();
      }
      if (isVisible && ref.current && !ref.current.contains(event.target as Node)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', closeDropDown, true);
    document.addEventListener('touchstart', closeDropDown, true);
    return () => {
      document.removeEventListener('touchstart', closeDropDown, true);
      document.removeEventListener('mousedown', closeDropDown, true);
    };
  }, [isVisible, forceUpdate]);

  return (
    <div
      ref={ref}
      className={cn(styles.container, customClass)}
    >
      <button
        type="button"
        className={styles.generalButton}
        onClick={() => setVisible(!isVisible)}
        disabled={disabled}
      >
        <Img src={iconSrc || DotMenuIcon} size={16} />
        <span className={styles.title}>
          {title}
        </span>
      </button>
      <div
        ref={containerRef}
        style={popperStyles.popper}
        className={cn(
          styles.dropBoxContainer,
          isVisible && styles.visibleDropBoxContainer,
        )}
      >
        {actions.map((item) => item && (
          <button
            key={item.id}
            type="button"
            className={styles.action}
            onClick={() => {
              setVisible(false);
              item.onPress();
            }}
            disabled={item.isDisabled}
          >
            <span>{item.title}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default memo(DropDownMenu);

import { FC, FormEventHandler, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../../components/Input/HookFormInput';
import { HookFormInputTypes } from '../../components/Input/interfaces/IHookFormInput';
import Button from '../../components/Button/Button';
import AuthForm from '../../components/AuthForm/AuthForm';
import { IAuthFooterLink } from '../../components/AuthForm/interfaces/IAuthFrom';
import { IRtkError } from '../../interfaces/general';
import { ISignUpFormData } from '../../interfaces/formsData';
import { useEvent } from '../../utils/hooks';
import { signUpFormValidation } from '../../utils/validations';
import { emptyFunc } from '../../utils/general';
import { useSignUpMutation } from '../../apis/authApi/authApi';
import { PathRoutes } from '../../constants/defines';
import styles from './styles/SignUpView.module.scss';

const SignUp: FC = () => {
  const { t } = useTranslation();
  const [
    signUpTrigger,
    { error, isLoading, isSuccess },
  ] = useSignUpMutation();
  const {
    register,
    reset,
    formState: { errors, touchedFields, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    resolver: yupResolver(signUpFormValidation),
    mode: 'onChange',
  });

  const email = register('email', { required: true });
  const firstName = register('firstName', { required: true });
  const lastName = register('lastName', { required: true });
  const password = register('password', { required: true });

  const onSubmit = useEvent((formData: ISignUpFormData) => {
    signUpTrigger(formData).unwrap().then(() => {
      reset();
    })
      .catch(() => { });
  });

  const footerLinks = useMemo<IAuthFooterLink[]>(() => [
    {
      title: t('signIn'),
      route: PathRoutes.SIGNIN,
    },
  ], [t]);

  return (
    <div className={styles.container}>
      <AuthForm
        title={t('signUp')}
        footerLinks={footerLinks}
        message={(error as IRtkError)?.message
          || (isSuccess ? t('confirmationEmailWasSent') : undefined)}
        isSuccessMessage={!error}
        isLoading={isLoading}
      >
        <form
          id="AuthSignUpForm"
          onSubmit={handleSubmit(onSubmit) as FormEventHandler<HTMLFormElement>}
          className={styles.content}
          autoComplete="on"
        >
          <HookFormInput
            placeholder={t('email')}
            ref={email.ref}
            name={email.name}
            onChange={email.onChange}
            onBlur={email.onBlur}
            autoComplete="username email"
            required={email.required}
            error={!isValid && touchedFields.email && errors.email?.message}
          />
          <HookFormInput
            placeholder={t('firstName')}
            ref={firstName.ref}
            name={firstName.name}
            onChange={firstName.onChange}
            onBlur={firstName.onBlur}
            required={firstName.required}
            error={!isValid && touchedFields.firstName && errors.firstName?.message}
          />
          <HookFormInput
            placeholder={t('lastName')}
            ref={lastName.ref}
            name={lastName.name}
            onChange={lastName.onChange}
            onBlur={lastName.onBlur}
            required={lastName.required}
            error={!isValid && touchedFields.lastName && errors.lastName?.message}
          />
          <HookFormInput
            placeholder={t('password')}
            ref={password.ref}
            name={password.name}
            onChange={password.onChange}
            onBlur={password.onBlur}
            type={HookFormInputTypes.PASSWORD}
            autoComplete="password new-password"
            required={password.required}
            error={!isValid && touchedFields.password && errors.password?.message}
          />
          <div className={styles.buttonContainer}>
            <Button
              title={t('signUp')}
              disabled={!isValid || isLoading}
              customClass={styles.button}
              onPress={emptyFunc}
              isSubmit
            />
          </div>
        </form>

      </AuthForm>
    </div>
  );
};

export default SignUp;

import {
  FC, useEffect, useRef,
} from 'react';
import { ToastContainer } from 'react-toastify';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { shallowEqual } from 'react-redux';
import Header from './components/Header';
import Main from './components/Main';
import SideBar from './components/SideBar';
import ErrorView from './components/ErrorView';
import { IElement, IProjectElement } from '../../interfaces/general';
import { useActions, useAppSelector, useEvent } from '../../utils/hooks';
import { onDragEnd } from '../../utils/dndUtils';
import { addElementToProject, getProjectsWithUpdatedProject } from '../../utils/projectUtils';
import { useGetProjectDetailsQuery } from '../../apis/projectsApi/projectsApi';
import { useUpdateElementsFolderListMutation, useUpdateElementMutation, useGetElementsFolderListQuery } from '../../apis/elementsApi/elementsApi';
import { emptyFunc } from '../../utils/general';
import { IS_ELECTRON } from '../../constants/general';
import styles from './styles/AppView.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const AppView: FC = () => {
  const folderPath = useAppSelector((state) => state.elements.folderPath, shallowEqual);
  const draftedProjects = useAppSelector((state) => state.projects.draftedProjects, shallowEqual);
  const currentProjectId = useAppSelector((state) => state.projects.currentProjectId, shallowEqual);
  const { dndTarget, generalError } = useAppSelector((state) => state.general, shallowEqual);
  const {
    elementsSetFolderPath,
    projectsSetDraftedProjects,
    generalSetDndTarget,
  } = useActions();
  const folderPathRef = useRef(folderPath);
  folderPathRef.current = folderPath;
  const folderId = (folderPath.length ? folderPath.at(-1)?.id : null) || null;
  const [updateElementTrigger] = useUpdateElementMutation({ fixedCacheKey: String(folderId) });
  const [updateElementsFolderListTrigger] = useUpdateElementsFolderListMutation();
  const {
    data: elementsFolderList,
  } = useGetElementsFolderListQuery(folderId);

  const draftedProject = draftedProjects.find((item) => item.id === currentProjectId);
  const { data } = useGetProjectDetailsQuery(
    currentProjectId as number,
    { skip: !!draftedProject || !currentProjectId },
  );
  const projectDetails = draftedProject || data;

  useEffect(() => {
    if (IS_ELECTRON) {
      window.electron?.changeTitle(projectDetails?.name);
    }
  }, [projectDetails?.name]);

  useEffect(() => {
    if (folderPathRef.current.some((item) => item.id === elementsFolderList?.folderInfo?.id)) {
      elementsSetFolderPath(folderPathRef.current.map((item) => {
        if (item.id === elementsFolderList?.folderInfo?.id) {
          return elementsFolderList?.folderInfo;
        }
        return item;
      }));
    }
  }, [elementsFolderList?.folderInfo, elementsSetFolderPath]);

  const setCurrentProjectElementsData = useEvent((result: IProjectElement[]) => {
    if (projectDetails) {
      projectsSetDraftedProjects(
        getProjectsWithUpdatedProject(
          draftedProjects,
          {
            ...projectDetails,
            elements: result,
          },
        ),
      );
    }
  });

  const updateElement = useEvent((element: IElement) => {
    updateElementTrigger(element).unwrap().catch(emptyFunc);
  });

  const updateFolderList = useEvent((elements: IElement[]) => {
    updateElementsFolderListTrigger({
      folderId: elementsFolderList?.folderInfo?.id || null,
      elements: elements.map(({ id }) => id),
    }).unwrap().catch(emptyFunc);
  });

  const dragEnd = useEvent((result: DropResult) => {
    onDragEnd(
      elementsFolderList?.elements || [],
      projectDetails?.elements || [],
      result,
      updateElement,
      updateFolderList,
      setCurrentProjectElementsData,
    );
  });

  const addComponent = useEvent((item: IElement) => {
    if (projectDetails?.elements) {
      addElementToProject(item, projectDetails.elements, setCurrentProjectElementsData);
    }
  });

  return (
    <div className={styles.content}>
      {generalError ? (
        <ErrorView message={generalError} />
      ) : (
        <>
          <Header />
          <DragDropContext onDragEnd={dragEnd}>
            <div className={styles.main}>
              <SideBar target={dndTarget} setTarget={generalSetDndTarget} onAddElementToProject={addComponent} />
              <Main onChangeTarget={generalSetDndTarget} target={dndTarget} />
            </div>
          </DragDropContext>
        </>
      )}
      <ToastContainer
        containerId="ToastContainer"
        position="bottom-right"
        theme="light"
      />
      <div id="ModalWindowContainer" />
    </div>
  );
};

export default AppView;

import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { IAuthRoute } from './interfaces/IAuthRoute';
import { PathRoutes } from '../../constants/defines';
import { useAppSelector } from '../../utils/hooks';

const NoAuthRoute: FC<IAuthRoute> = ({ children }) => {
  const authData = useAppSelector((state) => state.auth.authData, shallowEqual);

  return authData
    ? (<Navigate to={PathRoutes.HOME} replace />)
    : (children);
};

export default NoAuthRoute;

import { useFormik } from 'formik';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { folderFormValidation } from '../../../utils/validations';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import { IFolderForm } from '../interfaces/IFolderForm';
import styles from '../styles/FolderForm.module.scss';

const FolderForm: FC<IFolderForm> = ({ onSubmit, initValues }) => {
  const { t } = useTranslation();
  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: initValues || {
      name: '',
    },
    onSubmit,
    validationSchema: folderFormValidation,
  });

  return (
    <div className={styles.container}>
      <Input
        value={values.name}
        title={t('name')}
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
        error={touched.name && errors.name}
      />
      <div className={styles.footer}>
        <Button
          title={t(initValues ? 'save' : 'create')}
          disabled={!isValid || isSubmitting || !dirty}
          onPress={handleSubmit}
        />
      </div>
    </div>
  );
};

export default memo(FolderForm);

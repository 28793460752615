import { FC, memo, useMemo } from 'react';
import Img from '../Img/Img';
import { IIconButton } from './interfaces/IIconButton';
import { cn } from '../../utils/general';
import styles from './styles/IconButton.module.scss';
import { ImgStates } from '../../constants/defines';
import { getIconSet, getIconFromIconSetByState } from '../../utils/imgUtils';

const IconButton: FC<IIconButton> = ({
  icon,
  iconHover,
  iconActive,
  iconDisabled,
  iconSet = {},
  size,
  onPress,
  customClass,
  withFill,
  isVisible = true,
}) => {
  const Icon = useMemo(() => {
    const IconSet = getIconSet(icon, iconHover, iconActive, iconDisabled, iconSet);

    return IconSet.Icon ? (
      <>
        {Object.values(ImgStates).map((state) => {
          return (
            <Img key={state} src={getIconFromIconSetByState(state, IconSet)} size={size} state={state} />
          );
        })}
      </>
    ) : null;
  }, [icon, iconHover, iconActive, iconDisabled, iconSet, size]);

  return (
    <button
      type="button"
      onClick={() => onPress()}
      disabled={!isVisible}
      className={cn(styles.container, withFill && styles.fill, customClass)}
    >
      {Icon}
    </button>
  );
};

export default memo(IconButton);

import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import Button from '../../../components/Button/Button';
import ProjectsList from '../../../components/ProjectsList/ProjectsList';
import Loader from '../../../components/Loader/Loader';
import { IProjectsListItem } from '../../../interfaces/general';
import { useActions, useAppSelector, useEvent } from '../../../utils/hooks';
import { useGetProjectsListQuery, useUpdateProjectsListMutation } from '../../../apis/projectsApi/projectsApi';
import { emptyFunc } from '../../../utils/general';
import styles from '../styles/MainMenu.module.scss';

const MainMenu: FC = () => {
  const { t } = useTranslation();
  const {
    projectsTabs,
  } = useAppSelector((state) => state.projects, shallowEqual);
  const {
    projectsSetProjectsTabs,
    projectsSetCurrentProjectId,
    projectSetModalDeleteId,
    projectSetNewProject,
  } = useActions();
  const [updateProjectsListTrigger, { isLoading: isUpdateProjectsListLoading }] = useUpdateProjectsListMutation();
  const { data, isFetching } = useGetProjectsListQuery(null);

  const openModalCreate = useEvent(() => {
    projectSetNewProject(true);
  });

  const openProject = useEvent((item: IProjectsListItem) => {
    const isOpened = projectsTabs.some(({ id }) => id === item.id);
    if (!isOpened) {
      projectsSetProjectsTabs([...projectsTabs, item]);
    }
    projectsSetCurrentProjectId(item.id);
  });

  const setProject = useEvent((projectsListData: IProjectsListItem[]) => {
    if (JSON.stringify((data || []).map(({ id }) => id)) !== JSON.stringify(projectsListData.map(({ id }) => id))) {
      updateProjectsListTrigger(projectsListData.map(({ id }) => id)).unwrap().catch(emptyFunc);
    }
  });

  const showDeleteAlert = useEvent((projectData: IProjectsListItem) => {
    projectSetModalDeleteId(projectData.id);
  });

  return (
    <>
      <div className={styles.buttonsContainer}>
        <Button customClass={styles.button} title={t('createProject')} onPress={openModalCreate} />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          {(isFetching || isUpdateProjectsListLoading) ? (
            <Loader customClass={styles.loader} />
          ) : (
            <ProjectsList data={data || []} setData={setProject} onOpen={openProject} onDelete={showDeleteAlert} />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(MainMenu);

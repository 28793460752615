import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IElementFormData } from '../../../interfaces/general';
import { toAutomateFixed, toNumber } from '../../../utils/general';
import { getElementFormValidation } from '../../../utils/validations';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import { IElementForm } from '../interfaces/IElementForm';
import styles from '../styles/ElementForm.module.scss';

const ElementForm: FC<IElementForm> = ({
  onSubmit, initValues, isProjectElement, multiplier,
}) => {
  const { t } = useTranslation();
  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: initValues || {
      ...(!isProjectElement && {
        name: '',
      }),
      title: '',
      description: '',
      value: '',
    },
    onSubmit,
    validationSchema: getElementFormValidation(isProjectElement),
  });

  return (
    <div className={styles.container}>
      {!isProjectElement && (
        <Input
          value={(values as IElementFormData).name}
          title={t('name')}
          onChange={handleChange('name')}
          onBlur={handleBlur('name')}
          error={(touched as FormikTouched<IElementFormData>).name && (errors as FormikErrors<IElementFormData>).name}
        />
      )}
      <Input
        value={values.title}
        title={t('title')}
        onChange={handleChange('title')}
        onBlur={handleBlur('title')}
        error={touched.title && errors.title}
      />
      <Input
        value={values.description}
        title={t('description')}
        onChange={handleChange('description')}
        onBlur={handleBlur('description')}
        error={touched.description && errors.description}
        type="textarea"
      />
      <Input
        value={values.value}
        title={t('value')}
        onChange={handleChange('value')}
        onBlur={handleBlur('value')}
        error={touched.value && errors.value}
        type="number"
      />
      {multiplier && multiplier !== 1 && (
        <span className={styles.valueWithMultiplier}>
          {t('totalValue', { value: toAutomateFixed(multiplier * toNumber(values.value)) })}
        </span>
      )}
      <div className={styles.footer}>
        <Button
          title={t(initValues ? 'save' : 'create')}
          disabled={!isValid || isSubmitting || !dirty}
          onPress={handleSubmit}
        />
      </div>
    </div>
  );
};

export default memo(ElementForm);

import { configureStore } from '@reduxjs/toolkit';
import rootReducer, { IRootState } from './rootReducer';
import authApi from '../apis/authApi/authApi';
import projectsApi from '../apis/projectsApi/projectsApi';
import { errorHandler } from './midlewares';
import elementsApi from '../apis/elementsApi/elementsApi';

const setupStore = (preloadedState?: IRootState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(
        authApi.middleware,
        projectsApi.middleware,
        elementsApi.middleware,
        errorHandler,
      ),
  });
};

export type IAppStore = ReturnType<typeof setupStore>;
export type IAppDispatch = IAppStore['dispatch'];

export default setupStore;

import {
  ChangeEventHandler, FocusEventHandler, LegacyRef, forwardRef, memo, useState,
} from 'react';
import { HookFormInputTypes, IHookFormInput } from './interfaces/IHookFormInput';
import ErrorText from '../ErrorText/ErrorText';
import { cn } from '../../utils/general';
import styles from './styles/HookFormInput.module.scss';
import IconButton from '../IconButton/IconButton';
import { useEvent } from '../../utils/hooks';
import OpenEye from '../../assets/openEye.svg';
import CloseEye from '../../assets/closeEye.svg';

const HookFormInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, IHookFormInput>(({
  value,
  name,
  onChange,
  onBlur,
  disabled,
  max,
  min,
  maxLength,
  minLength,
  title,
  error,
  placeholder,
  required,
  customClassName,
  type,
  autoComplete,
  onFocus = () => { },
}, ref) => {
  const [stateType, setStateType] = useState(type);

  const toggleVisiblePassword = useEvent(() => {
    setStateType(stateType !== HookFormInputTypes.PASSWORD ? HookFormInputTypes.PASSWORD : HookFormInputTypes.TEXT);
  });

  const focus = () => {
    onFocus(name);
  };

  return (
    <div
      className={cn(
        styles.container,
        error && styles.errorContainer,
        type === HookFormInputTypes.PASSWORD && styles.password,
        customClassName,
      )}
    >
      {title && <span className={styles.title}>{title}</span>}
      <div className={styles.input}>
        {type === HookFormInputTypes.TEXTAREA ? (
          <textarea
            value={value}
            ref={ref as LegacyRef<HTMLTextAreaElement>}
            placeholder={placeholder}
            name={name}
            onBlur={onBlur as FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange as ChangeEventHandler<HTMLTextAreaElement> | undefined}
            required={required}
            onFocus={focus}
          />
        ) : (
          <input
            value={value}
            ref={ref as LegacyRef<HTMLInputElement>}
            placeholder={placeholder}
            name={name}
            onBlur={onBlur as FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined}
            disabled={disabled}
            max={max}
            min={min}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange as ChangeEventHandler<HTMLInputElement> | undefined}
            required={required}
            type={stateType}
            onFocus={focus}
            autoComplete={autoComplete || 'off'}
          />
        )}
        {type === HookFormInputTypes.PASSWORD && (
          <IconButton
            icon={stateType === HookFormInputTypes.PASSWORD ? CloseEye : OpenEye}
            customClass={styles.visibleIcon}
            onPress={toggleVisiblePassword}
            size={16}
          />
        )}
      </div>
      {error && <ErrorText error={error} />}
    </div>
  );
});

export default memo(HookFormInput);

import { IIconSet } from '../interfaces/general';
import ChevronIcon from '../assets/chevronDown.svg';
import HoverChevronIcon from '../assets/hoverChevronDown.svg';

export const pathBarBackIconSet: IIconSet = {
  Icon: ChevronIcon,
  IconHover: HoverChevronIcon,
};

export const dotIconSet: IIconSet = {
  Icon: ChevronIcon,
};

export const userIconSet: IIconSet = {
  Icon: ChevronIcon,
};

import {
  FC, FormEventHandler, useMemo,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../../components/Input/HookFormInput';
import Button from '../../components/Button/Button';
import AuthForm from '../../components/AuthForm/AuthForm';
import { IAuthFooterLink } from '../../components/AuthForm/interfaces/IAuthFrom';
import { IRtkError } from '../../interfaces/general';
import { IResetPasswordFormData } from '../../interfaces/formsData';
import { useEvent } from '../../utils/hooks';
import { resetPasswordFormValidation } from '../../utils/validations';
import { emptyFunc } from '../../utils/general';
import { useResetPasswordMutation } from '../../apis/authApi/authApi';
import { PathRoutes } from '../../constants/defines';
import styles from './styles/ResetPasswordView.module.scss';
import { HookFormInputTypes } from '../../components/Input/interfaces/IHookFormInput';

const ResetPasswordView: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [
    resetPasswordTrigger,
    { error, isLoading },
  ] = useResetPasswordMutation();

  const {
    register,
    formState: { errors, touchedFields, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      code: searchParams.get('code') || '',
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(resetPasswordFormValidation),
    mode: 'onChange',
  });

  const password = register('password', { required: true });
  const passwordConfirmation = register('passwordConfirmation', { required: true });

  const onSubmit = useEvent((formData: IResetPasswordFormData) => {
    resetPasswordTrigger(formData).unwrap()
      .then(() => {
        navigate(PathRoutes.SIGNIN);
      })
      .catch(emptyFunc);
  });

  const footerLinks = useMemo<IAuthFooterLink[]>(() => [
    {
      title: t('signIn'),
      route: PathRoutes.SIGNIN,
    },
  ], [t]);

  return (
    <div className={styles.container}>
      <AuthForm
        footerLinks={footerLinks}
        message={(error as IRtkError)?.message}
        title={t('resetPassword')}
        isSuccessMessage={false}
        isLoading={isLoading}
      >
        <form
          id="AuthForgotPasswordForm"
          onSubmit={handleSubmit(onSubmit) as FormEventHandler<HTMLFormElement>}
          className={styles.content}
          autoComplete="on"
        >
          <HookFormInput
            placeholder={t('newPassword')}
            ref={password.ref}
            name={password.name}
            onChange={password.onChange}
            onBlur={password.onBlur}
            autoComplete="password new-password"
            required={password.required}
            type={HookFormInputTypes.PASSWORD}
            error={!isValid && touchedFields.password && errors.password?.message}
          />
          <HookFormInput
            placeholder={t('passwordConfirmation')}
            ref={passwordConfirmation.ref}
            name={passwordConfirmation.name}
            onChange={passwordConfirmation.onChange}
            onBlur={passwordConfirmation.onBlur}
            required={passwordConfirmation.required}
            type={HookFormInputTypes.PASSWORD}
            error={!isValid && touchedFields.passwordConfirmation && errors.passwordConfirmation?.message}
          />
          <div className={styles.buttonContainer}>
            <Button
              title={t('resetPassword')}
              disabled={!isValid || isLoading}
              customClass={styles.button}
              onPress={emptyFunc}
              isSubmit
            />
          </div>
        </form>

      </AuthForm>
    </div>
  );
};

export default ResetPasswordView;

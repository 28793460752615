import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import ModalWindow from '../ModalWindow/ModalWindow';
import { IModalAgreement } from './interfaces/IModalAgreement';
import styles from './styles/ModalAgreement.module.scss';
import Loader from '../Loader/Loader';

const ModalAgreement: FC<IModalAgreement> = ({
  onClose, onAgree, isVisible, title, onDisagree, isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <ModalWindow title={title} onClose={onClose} isVisible={isVisible} width={400}>
      <div className={styles.content}>
        {isLoading ? (
          <Loader size={31} />
        ) : (
          <>
            <Button title={t('yes')} onPress={onAgree} />
            <Button title={t('no')} onPress={onDisagree || onClose} />
          </>
        )}
      </div>
    </ModalWindow>
  );
};

export default memo(ModalAgreement);

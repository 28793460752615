import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { localGetAuthData } from './localStore';
import { ReqMethods } from '../constants/defines';
import { IApiErrorObj, IApiErrorStandardData } from '../interfaces/responses';
import { IRtkError } from '../interfaces/general';
import { ICustomBaseQueryArgs, ICustomQueryArgs } from './interfaces/request';
import { getErrorMessage } from './general';

export const req = async <T = Record<string, unknown>>(
  url: string,
  method: ReqMethods = ReqMethods.GET,
  body?: Record<string, unknown>,
  isPublic?: boolean,
) => {
  let token = '';
  if (!isPublic) {
    const authData = await localGetAuthData().catch(() => (undefined)) || undefined;
    token = authData?.jwt || '';
  }
  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(token && { authorization: `Bearer ${token}` }),
    },
    body: body ? JSON.stringify(body) : undefined,
  })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json() as T;
        return data;
      }
      const errorData = await response.json() as IApiErrorStandardData;
      throw {
        status: errorData.error?.status || response.status,
        message: getErrorMessage(errorData),
        name: errorData.error?.name || response.statusText,
      } as IApiErrorObj & Error;
    })
    .catch((error: IApiErrorObj) => {
      throw {
        status: error.status || 500,
        message: error.message || 'Error',
        name: error.name || 'Error',
      } as IApiErrorObj & Error;
    });
};

export const customBaseQuery = ({
  baseUrl = '',
  isPublic,
}: ICustomBaseQueryArgs): BaseQueryFn<ICustomQueryArgs | string, unknown, IRtkError> => (
  async (args) => {
    try {
      const config = typeof args === 'string' ? {
        url: args,
      } : args;

      const result = await req(`${baseUrl}${config.url}`, config.method, config.body, isPublic);
      return { data: result };
    } catch (er) {
      const errorData = er as IApiErrorObj;
      return {
        error: {
          status: errorData?.status,
          message: errorData.message,
          name: errorData.name,
        },
      };
    }
  }
);

export const prepareAuthHeaders = async (headers: Headers) => {
  const authData = await localGetAuthData().catch(() => (undefined)) || undefined;
  const token = authData?.jwt || '';
  headers.set('authorization', `Bearer ${token}`);
  return headers;
};

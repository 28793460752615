export default {
  components: 'Components',
  edit: 'Edit',
  delete: 'Delete',
  duplicate: 'Duplicate',
  copy: 'Copy',
  cut: 'Cut',
  hours: 'Hours',
  tasks: 'Tasks',
  createElement: 'Create Element',
  editElement: 'Edit Element',
  createFolder: 'Create Folder',
  editFolder: 'Edit Folder',
  createProject: 'Create Project',
  openProject: 'Open Project',
  editProject: 'Edit Project',
  paste: 'Paste',
  wantDeleteFolderWithElements: 'Do you want delete folder with elements?',
  wantDeleteProject: 'Do you want delete project?',
  wantDeleteParentProjectElement: 'Do you want delete element with children?',
  wantSaveChanges: 'Do you want save current changes?',
  yes: 'Yes',
  no: 'No',
  create: 'Create',
  name: 'Name',
  title: 'Title',
  description: 'Description',
  value: 'Value (h)',
  isRequired: 'Is required',
  shouldBePositive: 'Should be a positive',
  maxFractionalDigits: 'Maximum {{- value}} fractional digits',
  authorName: 'author',
  selectProject: 'Select Project',
  multiplier: 'Multiplier',
  browserNotSupport: 'Browser not support application functionality. '
    + 'Please update current browser or try another browser.',
  cannotLoadElements: 'Cannot load Elements',
  totalValue: 'Total value with multiplier: {{value}} h',
  poweredBy: 'Powered by: {{- authorNameName}}',
  date: 'Date: {{- date}}',
  project: 'Project: {{- projectTitle}}',
  totalHours: 'Total Hours: {{- hours}} h',
  estimate: 'Estimate',
  save: 'Save',
  projectEstimation: 'Project Estimation',
  email: 'Email',
  password: 'Password',
  emailIsNotValid: 'Email is not valid',
  isToShort: 'Is To Short',
  signUp: 'Sign Up',
  signIn: 'Sign In',
  signOut: 'Sign Out',
  firstName: 'First Name',
  lastName: 'Last Name',
  forgotPassword: 'Forgot Password?',
  bothPassword: 'Both password need to be the same',
  sendEmail: 'Send Email',
  forgotEmailDesc: 'Please enter your email address. We will send you an email to reset your password',
  passChangeEmailSent: 'Password change email sent',
  resetPassword: 'Reset Password',
  newPassword: 'New Password',
  passwordConfirmation: 'Password Confirmation',
  confirmationEmailWasSent: 'A confirmation email was sent to your email address.',
  sendConfirmationEmail: 'Send Confirmation Email',
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAuthState } from './interfaces/IAuthState';
import { IAction } from '../../interfaces/general';
import { IAuthData } from '../../interfaces/responses';
import { localRemoveAuthData } from '../../utils/localStore';

export const initialState: IAuthState = {
  authData: null,
};

export const authLogout = createAsyncThunk(
  'auth/logout',
  async () => {
    await localRemoveAuthData().catch();
  },
);

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSetAuthData: (state, { payload }: IAction<IAuthData | null>) => {
      state.authData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogout.fulfilled, (state) => {
        state.authData = null;
      });
  },
});

export const authActions = auth.actions;

export default auth.reducer;

import {
  Middleware, MiddlewareAPI, isRejected, AnyAction,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IAction } from '../interfaces/general';
import { authLogout } from './auth/slice';
import { getErrorMessage } from '../utils/general';
import { IApiError } from '../interfaces/responses';

export const errorHandler: Middleware = ({ dispatch }: MiddlewareAPI) => (next) => (action: AnyAction) => {
  if (isRejected(action)) {
    if (((action as IAction<{ status: number | string }>).payload?.status as number) === 401
      || ((action as IAction<{ status: number | string }>).payload?.status as number) === 403) {
      dispatch(authLogout() as unknown as AnyAction);
    } else if (document?.getElementById('ToastContainer')) {
      toast.error(getErrorMessage((action.payload as { data: IApiError }).data));
    }
  }

  return next(action);
};

export default { errorHandler };

import { FC, memo } from 'react';
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import styles from './styles/ProjectList.module.scss';
import { getProjectsListStyle } from '../../utils/getStyles';
import { IProjectsList } from './interfaces/IProjectsList';
import ProjectOption from './components/ProjectOption';
import { useEvent } from '../../utils/hooks';

const ProjectsList: FC<IProjectsList> = ({
  data,
  onOpen,
  onDelete,
  setData,
}) => {
  const dragEnd = useEvent((result: DropResult) => {
    const { source, destination } = result;
    if (destination) {
      const dataClone = [...data];
      const [removed] = dataClone.splice(source.index, 1);
      dataClone.splice(destination.index, 0, removed);
      setData(dataClone);
    }
  });

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="ProjectsList">
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            style={getProjectsListStyle(droppableSnapshot)}
            data-rbd-droppable-context-id={droppableProvided.droppableProps['data-rbd-droppable-context-id']}
            data-rbd-droppable-id={droppableProvided.droppableProps['data-rbd-droppable-id']}
            className={styles.container}
          >
            {data.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}
              >
                {(provided, snapshot) => (
                  <ProjectOption
                    data={item}
                    provided={provided}
                    snapshot={snapshot}
                    onOpen={onOpen}
                    onDelete={onDelete}
                  />
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(ProjectsList);

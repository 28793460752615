import React, { FC, memo } from 'react';
import IErrorText from './interfaces/IErrorText';
import styles from './sass/ErrorText.module.scss';

const ErrorText: FC<IErrorText> = ({ error }) => (
  <span className={styles.error}>
    {error}
  </span>
);

export default memo(ErrorText);

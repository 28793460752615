import * as Yup from 'yup';
import i18n from '../i18n';
import { toNoExponentsString } from './general';

const { t } = i18n;

export const getElementFormValidation = (isProjectElement?: boolean) => Yup.object().shape({
  ...(!isProjectElement && {
    name: Yup.string().trim().required(t('isRequired')),
  }),
  title: Yup.string().trim().required(t('isRequired')),
  description: Yup.string().trim(),
  value: Yup.number().positive(t('shouldBePositive'))
    .test('maxFractionalDigits', '', function test(value) {
      const { path, createError } = this;
      const isError = value && toNoExponentsString(value).split('.')[1]?.length > 2;
      return isError ? createError({ path, message: t('maxFractionalDigits', { value: 2 }) }) : true;
    }).required(t('isRequired')),
});

export const folderFormValidation = Yup.object().shape({
  name: Yup.string().trim().required(t('isRequired')),
});

export const projectFormValidation = Yup.object().shape({
  name: Yup.string().trim().required(t('isRequired')),
  title: Yup.string().trim().required(t('isRequired')),
});

export const signInFormValidation = Yup.object().shape({
  email: Yup.string().trim().email(t('emailIsNotValid')).required(t('isRequired')),
  password: Yup.string().min(6, t('isToShort')).required(t('isRequired')),
});

export const signUpFormValidation = Yup.object().shape({
  email: Yup.string().trim().email(t('emailIsNotValid')).required(t('isRequired')),
  firstName: Yup.string().trim().required(t('isRequired')),
  lastName: Yup.string().trim().required(t('isRequired')),
  password: Yup.string().min(6, t('isToShort')).required(t('isRequired')),
});

export const sendEmailConfirmationFormValidation = Yup.object().shape({
  email: Yup.string().trim().email(t('emailIsNotValid')).required(t('isRequired')),
});

export const forgotPasswordFormValidation = Yup.object().shape({
  email: Yup.string().trim().email(t('emailIsNotValid')).required(t('isRequired')),
});

export const resetPasswordFormValidation = Yup.object().shape({
  code: Yup.string(),
  password: Yup.string().min(6, t('isToShort')).required(t('isRequired')),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], t('bothPassword')).required(t('isRequired')),
});

import { ChangeEventHandler, FocusEventHandler } from 'react';
import { ChangeHandler } from 'react-hook-form';

export enum HookFormInputTypes {
  PASSWORD = 'password',
  TEXTAREA = 'textarea',
  TEXT = 'text',
}

export interface IHookFormInput {
  name: string
  onChange: ChangeHandler | ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: ChangeHandler | FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
  max?: number | string
  min?: number | string
  maxLength?: number
  minLength?: number
  title?: string
  error?: string | false
  required?: boolean
  placeholder?: string
  customClassName?: string
  type?: HookFormInputTypes
  onFocus?: (name: string) => void | FocusEventHandler<HTMLInputElement>
  autoComplete?: string
  value?: string
}

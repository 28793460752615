import {
  FC, memo, useEffect, useMemo,
} from 'react';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import FolderDetails from '../../../components/FolderDetails/FolderDetails';
import ElementsList from '../../../components/ElementsList/ElementsList';
import ModalAgreement from '../../../components/ModalAgreement/ModalAgreement';
import Loader from '../../../components/Loader/Loader';
import ModalElementForm from '../../../components/ModalForms/ModalElementForm';
import ModalFolderForm from '../../../components/ModalForms/ModalFolderForm';
import { IDropDownMenuOption } from '../../../components/DropDownMenu/interfaces/IDropDownMenu';
import {
  IElement, IElementFormData, IFolderFormData, IFolderPathItem, IProjectElementFormData,
} from '../../../interfaces/general';
import { ISideBar } from '../interfaces/ISideBar';
import { useActions, useAppSelector, useEvent } from '../../../utils/hooks';
import {
  getNewArrWithExcludedLastItem, trimValuesInObj, emptyFunc,
} from '../../../utils/general';
import elementsApi, {
  useCreateElementMutation,
  useDeleteElementMutation,
  useUpdateElementMutation,
} from '../../../apis/elementsApi/elementsApi';
import {
  ElementMenuActionTypes, ElementTypes, ProjectViewTargets,
} from '../../../constants/defines';
import styles from '../styles/SideBar.module.scss';

const SideBar: FC<ISideBar> = ({
  setTarget, target, onAddElementToProject,
}) => {
  const { t } = useTranslation();
  const {
    folderPath,
    cutOutElement,
    modalDeleteId,
    activeModalFormsData,
    isNewElementsItem,
    isNewElementsFolder,
  } = useAppSelector((state) => state.elements, shallowEqual);
  const currentProjectId = useAppSelector((state) => state.projects.currentProjectId, shallowEqual);
  const {
    elementsSetFolderPath,
    elementsSetCutOutElement,
    elementsSetModalDeleteId,
    elementsSetActiveModalFormData,
    elementsSetNewElementsFolder,
    elementsSetNewElementsItem,
  } = useActions();
  const [createElementTrigger] = useCreateElementMutation();
  const [deleteElementTrigger, { isLoading: isDeleteElementLoading }] = useDeleteElementMutation();
  const folderId = (folderPath.length ? folderPath.at(-1)?.id : null) || null;
  const [
    updateElementTrigger,
    { isLoading: isUpdateElementLoading },
  ] = useUpdateElementMutation({ fixedCacheKey: String(folderId) });
  const isUpdateElementsFolderListLoading = useAppSelector((state) => (
    Object.values(state.elementsApi.mutations)
      .some((item) => item?.endpointName === 'updateElementsFolderList' && item?.status === QueryStatus.pending)
  ), shallowEqual);
  const { data: elementsFolderList, isFetching } = elementsApi.endpoints.getElementsFolderList
    .useQueryState(folderId);
  const isLoading = isFetching || isDeleteElementLoading || isUpdateElementLoading || isUpdateElementsFolderListLoading;

  const closeModals = useEvent(() => {
    elementsSetActiveModalFormData(null);
    elementsSetNewElementsFolder(false);
    elementsSetNewElementsItem(false);
  });

  useEffect(() => {
    if (cutOutElement && elementsFolderList?.elements.some((item) => item.id === cutOutElement?.id)) {
      elementsSetCutOutElement(elementsFolderList?.elements.find((item) => item.id === cutOutElement?.id) || null);
    }
  }, [cutOutElement, elementsFolderList?.elements, elementsSetCutOutElement]);

  const deleteElement = useEvent((item: IElement | IFolderPathItem) => {
    if (!item.itHasChildren) {
      if (elementsFolderList?.folderInfo?.id === item.id) {
        elementsSetFolderPath(getNewArrWithExcludedLastItem(folderPath));
      }
      deleteElementTrigger(item.id).unwrap()
        .catch(() => {
          elementsSetFolderPath(folderPath);
        });
    } else {
      elementsSetModalDeleteId(item.id);
    }
  });

  const pasteCutOutElement = useEvent(() => {
    if (cutOutElement) {
      updateElementTrigger({
        ...cutOutElement,
        folderId,
      }).unwrap()
        .then(() => {
          elementsSetCutOutElement(null);
        })
        .catch(emptyFunc);
    }
  });

  const submitElementForm = useEvent((
    values: IElementFormData | IProjectElementFormData,
    { setSubmitting }: FormikHelpers<IElementFormData | IProjectElementFormData>,
  ) => {
    if (activeModalFormsData) {
      const body = trimValuesInObj<IElement>({
        ...values,
        value: parseFloat((values as IElementFormData).value),
      });

      updateElementTrigger(body).unwrap()
        .then(() => {
          setSubmitting(false);
          closeModals();
        }).catch(() => {
          setSubmitting(false);
        });
    } else { // create
      const body = trimValuesInObj<IElement>({
        ...values,
        folderId,
        type: ElementTypes.ITEM,
        value: parseFloat(values.value),
      });
      createElementTrigger(body).unwrap()
        .then(() => {
          setSubmitting(false);
          closeModals();
        }).catch(() => {
          setSubmitting(false);
        });
    }
  });

  const submitFolderForm = useEvent((
    values: IFolderFormData,
    { setSubmitting }: FormikHelpers<IFolderFormData>,
  ) => {
    if (activeModalFormsData) { // edit
      const body = trimValuesInObj<IElement>({
        ...values,
      });

      updateElementTrigger(body).unwrap()
        .then(() => {
          setSubmitting(false);
          closeModals();
        }).catch(() => {
          setSubmitting(false);
        });
    } else { // create
      const body = trimValuesInObj<IElement>({
        ...values,
        type: ElementTypes.FOLDER,
        folderId,
      });

      createElementTrigger(body).unwrap()
        .then(() => {
          setSubmitting(false);
          closeModals();
        }).catch(() => {
          setSubmitting(false);
        });
    }
  });

  const openFolder = useEvent((folder: IFolderPathItem) => {
    elementsSetFolderPath([...folderPath, folder]);
  });

  const closeModalAgreement = useEvent(() => {
    elementsSetModalDeleteId(null);
  });

  const deleteFolderWithElements = useEvent(() => {
    if (elementsFolderList?.folderInfo?.id === modalDeleteId) {
      elementsSetFolderPath(getNewArrWithExcludedLastItem(folderPath));
    }
    deleteElementTrigger(modalDeleteId || '').unwrap()
      .then(() => {
        elementsSetModalDeleteId(null);
      })
      .catch(() => {
        elementsSetFolderPath(folderPath);
      });
  });

  const menuAction = useEvent((type: ElementMenuActionTypes, element: IElement) => {
    switch (type) {
      case ElementMenuActionTypes.CUT:
        elementsSetCutOutElement(element);
        break;

      case ElementMenuActionTypes.DELETE:
        deleteElement(element);
        break;

      case ElementMenuActionTypes.EDIT: {
        const elementFormData = {
          id: element.id,
          type: element.type,
          name: element.name,
          ...(element.type === ElementTypes.ITEM && {
            title: element.title || '',
            description: element.description || '',
            value: String(element.value || 0),
          }),
        };
        elementsSetActiveModalFormData(elementFormData);
        break;
      }
      default:
        break;
    }
  });

  const folderOptions = useMemo<IDropDownMenuOption[]>(() => [
    {
      id: 'createElement',
      title: t('createElement'),
      onPress: () => {
        elementsSetNewElementsItem(true);
      },
    },
    {
      id: 'createFolder',
      title: t('createFolder'),
      onPress: () => {
        elementsSetNewElementsFolder(true);
      },
    },
    {
      id: 'paste',
      title: t('paste'),
      onPress: pasteCutOutElement,
      isDisabled: !cutOutElement,
    },
    ...(elementsFolderList?.folderInfo ? [
      {
        id: 'edit',
        title: t('edit'),
        onPress: () => {
          elementsSetActiveModalFormData({
            id: elementsFolderList?.folderInfo?.id || 0,
            name: elementsFolderList?.folderInfo?.name || '',
            type: ElementTypes.FOLDER,
          });
        },
      },
      {
        id: 'delete',
        title: t('delete'),
        onPress: () => {
          deleteElement(elementsFolderList?.folderInfo as IFolderPathItem);
        },
      },
    ] : []),
  ], [
    t,
    elementsFolderList?.folderInfo,
    cutOutElement,
    deleteElement,
    pasteCutOutElement,
    elementsSetActiveModalFormData,
    elementsSetNewElementsItem,
    elementsSetNewElementsFolder,
  ]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader customClass={styles.loader} />
      ) : (
        <>
          <FolderDetails name={elementsFolderList?.folderInfo?.name || 'Elements'} menuOptions={folderOptions} />
          <ElementsList
            id={elementsFolderList?.folderInfo?.id || 0}
            data={elementsFolderList?.elements || []}
            isVisibleAddButton={!!currentProjectId}
            onChangeTarget={setTarget}
            type={target === ProjectViewTargets.FOLDER ? ProjectViewTargets.FOLDER : undefined}
            onOpenFolder={openFolder}
            onAddElementToProject={onAddElementToProject}
            menuAction={menuAction}
            cutOutElementId={cutOutElement?.id}
          />
        </>
      )}
      <ModalAgreement
        title={t('wantDeleteFolderWithElements')}
        isVisible={!!modalDeleteId}
        onClose={closeModalAgreement}
        onAgree={deleteFolderWithElements}
        isLoading={isDeleteElementLoading}
      />
      <ModalElementForm
        title={t(activeModalFormsData ? 'editElement' : 'createElement')}
        isVisible={isNewElementsItem || activeModalFormsData?.type === ElementTypes.ITEM}
        onClose={closeModals}
        onSubmit={submitElementForm}
        initValues={activeModalFormsData as IElementFormData}
      />
      <ModalFolderForm
        title={t(activeModalFormsData ? 'editFolder' : 'createFolder')}
        isVisible={isNewElementsFolder || activeModalFormsData?.type === ElementTypes.FOLDER}
        onClose={closeModals}
        onSubmit={submitFolderForm}
        initValues={activeModalFormsData}
      />
    </div>
  );
};

export default memo(SideBar);

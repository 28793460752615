import { FC, memo } from 'react';
import ModalWindow from '../ModalWindow/ModalWindow';
import FolderForm from './components/FolderForm';
import { IModalFolderForm } from './interfaces/IModalFolderForm';

const ModalFolderForm: FC<IModalFolderForm> = ({
  onClose, onSubmit, isVisible, title, initValues,
}) => (
  <ModalWindow title={title} onClose={onClose} isVisible={isVisible}>
    <FolderForm onSubmit={onSubmit} initValues={initValues} />
  </ModalWindow>
);

export default memo(ModalFolderForm);

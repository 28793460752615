import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { IAuthRoute } from './interfaces/IAuthRoute';
import { PathRoutes } from '../../constants/defines';
import { useAppSelector } from '../../utils/hooks';

const AuthRoute: FC<IAuthRoute> = ({ children }) => {
  const authData = useAppSelector((state) => state.auth.authData, shallowEqual);

  return authData
    ? (children)
    : (<Navigate to={PathRoutes.SIGNIN} replace />);
};

export default AuthRoute;

import { FC, memo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ElementsListItem from './components/ElementsListItem';
import { IElementsList } from './interfaces/IElementsList';
import styles from './styles/ElementsList.module.scss';
import { DataPrefix } from '../../constants/defines';
import { getElementsListStyle } from '../../utils/getStyles';
import { checkIsFolder, getDragDropUId } from '../../utils/projectUtils';

const ElementsList: FC<IElementsList> = ({
  id,
  data,
  onAddElementToProject,
  onChangeTarget,
  onOpenFolder,
  type,
  menuAction,
  isVisibleAddButton,
  cutOutElementId,
}) => {
  return (
    <Droppable
      droppableId={id
        ? getDragDropUId(DataPrefix.ELEMENTS_FOLDER, id)
        : getDragDropUId(DataPrefix.ELEMENT_ROOT_FOLDER)}
      type={type}
      isCombineEnabled
    >
      {(droppableProvided, droppableSnapshot) => (
        <div
          ref={droppableProvided.innerRef}
          style={getElementsListStyle(droppableSnapshot)}
          data-rbd-droppable-context-id={droppableProvided.droppableProps['data-rbd-droppable-context-id']}
          data-rbd-droppable-id={droppableProvided.droppableProps['data-rbd-droppable-id']}
          className={styles.container}
        >
          {data.map((item, index) => (
            <Draggable
              key={item.id}
              draggableId={getDragDropUId(checkIsFolder(item)
                ? DataPrefix.ELEMENTS_FOLDER
                : DataPrefix.ELEMENTS_ITEM, item.id)}
              index={index}
            >
              {(provided, snapshot) => (
                <ElementsListItem
                  data={item}
                  onChangeTarget={onChangeTarget}
                  onAddElementToProject={onAddElementToProject}
                  onOpenFolder={onOpenFolder}
                  provided={provided}
                  snapshot={snapshot}
                  isCombineStatus={snapshot.combineWith?.startsWith(DataPrefix.ELEMENTS_FOLDER)
                    || snapshot.combineWith?.startsWith(DataPrefix.PROJECT)}
                  menuAction={menuAction}
                  isVisibleAddButton={isVisibleAddButton}
                  isCutOutElement={item.id === cutOutElementId}
                />
              )}
            </Draggable>
          ))}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default memo(ElementsList);

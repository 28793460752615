import { FC, memo } from 'react';
import IconButton from '../IconButton/IconButton';
import { IPathBar } from './interfaces/IPathBar';
import styles from './styles/PathBar.module.scss';
import { pathBarBackIconSet } from '../../constants/iconSets';

const PathBar: FC<IPathBar> = ({
  data,
  onFolderPress,
  onBack,
}) => {
  return (
    <div className={styles.container}>
      {!!data.length && (
        <IconButton customClass={styles.goBackButton} onPress={onBack} iconSet={pathBarBackIconSet} size={18} />
      )}
      <button
        type="button"
        onClick={() => onFolderPress(null)}
        className={styles.folder}
      >
        <span>Elements</span>
      </button>
      {data.map((item) => (
        <button
          key={item.id}
          type="button"
          onClick={() => onFolderPress(item.id)}
          className={styles.folder}
        >
          <span>{item.name}</span>
        </button>
      ))}
    </div>
  );
};

export default memo(PathBar);

import {
  FC, memo,
} from 'react';
import styles from '../styles/ProjectOption.module.scss';
import { getElementStyle } from '../../../utils/getStyles';
import { IProjectOption } from '../interfaces/IProjectOption';
import IconButton from '../../IconButton/IconButton';
import { useEvent } from '../../../utils/hooks';
import DeleteIcon from '../../../assets/delete.svg';
import OpenIcon from '../../../assets/open.svg';

const ProjectOption: FC<IProjectOption> = ({
  data,
  onOpen,
  onDelete,
  provided,
  snapshot,
}) => {
  const open = useEvent(() => {
    onOpen(data);
  });

  const deleteProject = useEvent(() => {
    onDelete(data);
  });

  return (
    <div
      ref={provided.innerRef}
      data-rbd-draggable-context-id={provided.draggableProps['data-rbd-draggable-context-id']}
      data-rbd-draggable-id={provided.draggableProps['data-rbd-draggable-id']}
      onTransitionEnd={provided.draggableProps.onTransitionEnd}
      aria-describedby={provided.dragHandleProps?.['aria-describedby']}
      data-rbd-drag-handle-context-id={provided.dragHandleProps?.['data-rbd-drag-handle-context-id']}
      data-rbd-drag-handle-draggable-id={provided.dragHandleProps?.['data-rbd-drag-handle-draggable-id']}
      draggable={provided.dragHandleProps?.draggable}
      onDragStart={provided.dragHandleProps?.onDragStart}
      role="button"
      tabIndex={provided.dragHandleProps?.tabIndex}
      onDoubleClick={open}
      style={getElementStyle(snapshot, provided.draggableProps.style)}
      className={styles.container}
    >
      <span className={styles.title}>{data.name}</span>
      <div className={styles.buttonsContainer}>
        <IconButton icon={DeleteIcon} size={14} onPress={deleteProject} withFill />
        <IconButton icon={OpenIcon} size={14} onPress={open} withFill />
      </div>
    </div>
  );
};

export default memo(ProjectOption);

import { FC, memo } from 'react';
import { ITextButton } from './interfaces/ITextButton';
import { cn } from '../../utils/general';
import styles from './styles/TextButton.module.scss';

const IconButton: FC<ITextButton> = ({
  title,
  onPress,
  customClass,
  disabled,
}) => {
  return (
    <button
      type="button"
      onClick={() => onPress()}
      className={cn(styles.container, customClass)}
      disabled={disabled}
    >
      <span>{title}</span>
    </button>
  );
};

export default memo(IconButton);

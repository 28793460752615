import {
  FC, memo, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import IconButton from '../../IconButton/IconButton';
import { IHeaderUser } from '../interfaces/IHeaderUser';
import { useEvent } from '../../../utils/hooks';
import { cn } from '../../../utils/general';
import styles from '../styles/HeaderUser.module.scss';
import UserIcon from '../../../assets/user.svg';

const HeaderUser: FC<IHeaderUser> = ({ onLogout, user }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState(false);

  const { styles: popperStyles, forceUpdate } = usePopper(ref.current, containerRef.current, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 2],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      {
        name: 'eventListeners',
        options: {
          resize: isVisible,
          scroll: isVisible,
        },
      },
    ],
  });

  useEffect(() => {
    const closeDropDown = (event: HTMLElementEventMap['click'] | TouchEvent) => {
      if (forceUpdate && isVisible) {
        forceUpdate();
      }
      if (isVisible && ref.current && !ref.current.contains(event.target as Node)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', closeDropDown, true);
    document.addEventListener('touchstart', closeDropDown, true);
    return () => {
      document.removeEventListener('touchstart', closeDropDown, true);
      document.removeEventListener('mousedown', closeDropDown, true);
    };
  }, [isVisible, forceUpdate]);

  const toggleVisible = useEvent(() => {
    setVisible(!isVisible);
  });

  return (
    <div
      ref={ref}
      className={cn(styles.container)}
    >
      <IconButton
        icon={UserIcon}
        size={17}
        onPress={toggleVisible}
        customClass={styles.generalButton}
      />
      <div
        ref={containerRef}
        style={popperStyles.popper}
        className={cn(
          styles.dropBoxContainer,
          isVisible && styles.visibleDropBoxContainer,
        )}
      >
        <span className={styles.name}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</span>
        <span className={styles.email}>{user?.email || ''}</span>
        <div className={styles.buttonsContainer}>
          <button
            type="button"
            onClick={() => onLogout()}
            className={styles.button}
          >
            <span>{t('signOut')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderUser);

import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber, toAutomateFixed } from '../../utils/general';
import { IProjectFooter } from './interfaces/IProjectFooter';
import styles from './styles/ProjectFooter.module.scss';

const ProjectFooter: FC<IProjectFooter> = ({
  project,
}) => {
  const { t } = useTranslation();
  const totalEstimateHours = useMemo(() => (
    project.elements.reduce(
      (acc, elem) => acc + toNumber(toAutomateFixed(elem.value * project.multiplier)),
      0,
    )), [project]);

  return (
    <div className={styles.container}>
      <span>{t('totalHours', { hours: toAutomateFixed(totalEstimateHours) })}</span>
    </div>
  );
};

export default memo(ProjectFooter);

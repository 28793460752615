import {
  FC, FormEventHandler, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../../components/Input/HookFormInput';
import Button from '../../components/Button/Button';
import AuthForm from '../../components/AuthForm/AuthForm';
import { IForgotPasswordFormData } from '../../interfaces/formsData';
import { IRtkError } from '../../interfaces/general';
import { IAuthFooterLink } from '../../components/AuthForm/interfaces/IAuthFrom';
import { useEvent } from '../../utils/hooks';
import { forgotPasswordFormValidation } from '../../utils/validations';
import { emptyFunc } from '../../utils/general';
import { useForgotPasswordMutation } from '../../apis/authApi/authApi';
import { PathRoutes } from '../../constants/defines';
import styles from './styles/ForgotPasswordView.module.scss';

const ForgotPasswordView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    forgotPasswordTrigger,
    { error, isLoading },
  ] = useForgotPasswordMutation();

  const {
    register,
    formState: { errors, touchedFields, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotPasswordFormValidation),
    mode: 'onChange',
  });

  const email = register('email', { required: true });

  const onSubmit = useEvent((formData: IForgotPasswordFormData) => {
    forgotPasswordTrigger(formData).unwrap()
      .then(() => {
        navigate(PathRoutes.SIGNIN);
      })
      .catch(emptyFunc);
  });

  const footerLinks = useMemo<IAuthFooterLink[]>(() => [
    {
      title: t('signIn'),
      route: PathRoutes.SIGNIN,
    },
  ], [t]);

  return (
    <div className={styles.container}>
      <AuthForm
        footerLinks={footerLinks}
        message={(error as IRtkError)?.message}
        isSuccessMessage={false}
        isLoading={isLoading}
      >
        <form
          id="AuthForgotPasswordForm"
          onSubmit={handleSubmit(onSubmit) as FormEventHandler<HTMLFormElement>}
          className={styles.content}
          autoComplete="on"
        >
          <p className={styles.desc}>
            {t('forgotEmailDesc')}
          </p>
          <HookFormInput
            placeholder={t('email')}
            ref={email.ref}
            name={email.name}
            onChange={email.onChange}
            onBlur={email.onBlur}
            autoComplete="username email"
            required={email.required}
            error={!isValid && touchedFields.email && errors.email?.message}
          />
          <div className={styles.buttonContainer}>
            <Button
              title={t('sendEmail')}
              disabled={!isValid || isLoading}
              customClass={styles.button}
              onPress={emptyFunc}
              isSubmit
            />
          </div>
        </form>

      </AuthForm>
    </div>
  );
};

export default ForgotPasswordView;

import { FC, memo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ProjectElementsItem from './ChildProjectElementsItem';
import { IChildProjectElementDroppable } from '../interfaces/IChildProjectElementDroppable';
import { getProjectElementsListStyle } from '../../../utils/getStyles';
import styles from '../styles/ChildProjectElementDroppable.module.scss';

const ChildProjectElementDroppable: FC<IChildProjectElementDroppable> = ({
  data, uid, onChangeOrder, menuAction, multiplier, isMenuVisible,
}) => {
  return (
    <Droppable
      droppableId={uid}
      type={uid}
    >
      {(droppableProvided, droppableSnapshot) => (
        <div
          className={styles.container}
          ref={droppableProvided.innerRef}
          style={getProjectElementsListStyle(droppableSnapshot)}
          data-rbd-droppable-context-id={droppableProvided.droppableProps['data-rbd-droppable-context-id']}
          data-rbd-droppable-id={droppableProvided.droppableProps['data-rbd-droppable-id']}
        >
          {data.map((item, index) => (
            <Draggable
              key={item.uid}
              draggableId={item.uid}
              index={index}
            >
              {(provided, snapshot) => (
                <ProjectElementsItem
                  provided={provided}
                  snapshot={snapshot}
                  maxOrder={Math.max((data[index - 1]?.order || 0) + 1, 1)}
                  data={item}
                  onChangeOrder={onChangeOrder}
                  menuAction={menuAction}
                  multiplier={multiplier}
                  isMenuVisible={isMenuVisible}
                />
              )}
            </Draggable>
          ))}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default memo(ChildProjectElementDroppable);

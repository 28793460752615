import { FC, memo } from 'react';
import { IImg } from './interfaces/IImg';
import { cn } from '../../utils/general';
import styles from './styles/Img.module.scss';
import { ImgStates } from '../../constants/defines';

const Img: FC<IImg> = ({
  src,
  size,
  state,
  alt = '',
  customClass,
}) => {
  const Size = {
    width: (typeof size === 'object' ? size.width : size) || '100%',
    height: (typeof size === 'object' ? size.height : size) || '100%',
  };
  return (
    <img
      data-img-state={state || ImgStates.IDLE}
      src={src}
      className={cn(styles.image, customClass)}
      width={Size.width}
      height={Size.height}
      alt={alt}
    />
  );
};

export default memo(Img);

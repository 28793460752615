import {
  FC, memo, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import Loader from '../Loader/Loader';
import IconButton from '../IconButton/IconButton';
import Input from '../Input/Input';
import { IProjectDetails } from './interfaces/IProjectDetails';
import styles from './styles/ProjectDetails.module.scss';
import SaveIcon from '../../assets/save.svg';
import PrintIcon from '../../assets/print.svg';

const ProjectDetails: FC<IProjectDetails> = ({
  name, menuOptions, multiplier, onChangeMultiplier, onSave, isVisibleSaveButton, onPrint, isLoading,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number | string>(multiplier);

  const setTempValue = useEvent((val: string) => {
    setValue(val);
  });

  const setMultiplier = useEvent(() => {
    const val = !Number.isNaN(Number(value)) ? (Number(value) || 1) : 1;
    setValue(val);
    if (val !== multiplier) {
      onChangeMultiplier(val);
    }
  });

  useEffect(() => {
    setValue(multiplier);
  }, [multiplier]);

  return (
    <div className={styles.container}>
      <span className={styles.name}>
        {name}
      </span>
      {isLoading ? (
        <Loader size={26} customClass={styles.loader} showAnimationDuration={1000} />
      ) : (
        <IconButton
          icon={SaveIcon}
          onPress={onSave}
          size={26}
          customClass={styles.iconButton}
          isVisible={isVisibleSaveButton}
        />
      )}

      <IconButton
        icon={PrintIcon}
        onPress={onPrint}
        size={26}
        customClass={styles.iconButton}
      />
      <Input
        customClassName={styles.multiplier}
        title={t('multiplier')}
        value={value}
        onChange={setTempValue}
        onBlur={setMultiplier}
        type="number"
      />
      <DropDownMenu actions={menuOptions} />
    </div>
  );
};

export default memo(ProjectDetails);

import { FC, memo } from 'react';
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import styles from './styles/HeaderTabs.module.scss';
import { IHeaderTabs } from './interfaces/IHeaderTabs';
import { useEvent } from '../../utils/hooks';
import HeaderTabsItem from './components/HeaderTabsItem';
import IconButton from '../IconButton/IconButton';
import { cn } from '../../utils/general';
import HomeIcon from '../../assets/home.svg';
import ActiveHomeIcon from '../../assets/activeHome.svg';
import HeaderUser from './components/HeaderUser';

const HeaderTabs: FC<IHeaderTabs> = ({
  data,
  setData,
  openMenu,
  activeTabId,
  onTabClick,
  onClose,
  draftedProjectsIds,
  user,
  onLogout,
}) => {
  const dragEnd = useEvent((result: DropResult) => {
    const { source, destination } = result;
    if (destination) {
      const dataClone = [...data];
      const [removed] = dataClone.splice(source.index, 1);
      dataClone.splice(destination.index, 0, removed);
      setData(dataClone);
    }
  });

  return (
    <div className={styles.container}>
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="HeaderTabs" direction="horizontal">
          {(droppableProvided) => (
            <div
              className={styles.droppableContainer}
              ref={droppableProvided.innerRef}
              data-rbd-droppable-context-id={droppableProvided.droppableProps['data-rbd-droppable-context-id']}
              data-rbd-droppable-id={droppableProvided.droppableProps['data-rbd-droppable-id']}
            >
              {data.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={String(item.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <HeaderTabsItem
                      isActive={activeTabId === item.id}
                      item={item}
                      provided={provided}
                      snapshot={snapshot}
                      onTabClick={onTabClick}
                      onClose={onClose}
                      isDrafted={draftedProjectsIds.some((id) => id === item.id)}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <IconButton
        icon={!activeTabId ? ActiveHomeIcon : HomeIcon}
        size={16}
        onPress={openMenu}
        customClass={cn(styles.home, !activeTabId && styles.activeHome)}
      />
      <HeaderUser user={user} onLogout={onLogout} />
    </div>
  );
};

export default memo(HeaderTabs);

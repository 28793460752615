import { FC, memo } from 'react';
import { createPortal } from 'react-dom';
import { cn } from '../../utils/general';
import IconButton from '../IconButton/IconButton';
import { IModalWindow } from './interfaces/IModalWindow';
import styles from './styles/ModalWindow.module.scss';
import CloseIcon from '../../assets/close.svg';

const ModalWindow: FC<IModalWindow> = ({
  width = 600,
  customClass,
  children,
  isVisible,
  title,
  onClose,
}) => {
  const container = document.getElementById('ModalWindowContainer');
  return (
    isVisible && container ? (
      createPortal(
        <div className={styles.container}>
          <div className={cn(styles.body, customClass)} style={{ width }}>
            <div className={styles.header}>
              <span>{title}</span>
              <IconButton onPress={onClose} icon={CloseIcon} size={24} />
            </div>
            {children}
          </div>
        </div>,
        container,
      )
    ) : null
  );
};

export default memo(ModalWindow);

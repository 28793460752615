import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../constants/general';
import { ReqMethods } from '../../constants/defines';
import { prepareAuthHeaders } from '../../utils/request';
import { ICreateProjectData, IProject, IProjectsListItem } from '../../interfaces/general';
import { IResponseData } from '../../interfaces/responses';

const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/projects`,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['ProjectsList', 'Project'],
  endpoints: (builder) => ({
    getProjectsList: builder.query<IProjectsListItem[], null>({
      query: () => ({
        url: '/list',
      }),
      transformResponse: (response: IResponseData<IProjectsListItem[]>) => response.data,
      providesTags: ['ProjectsList'],
    }),
    updateProjectsList: builder.mutation<IProjectsListItem[], number[]>({
      query: (body) => ({
        url: '/list',
        method: ReqMethods.PUT,
        body: {
          data: body,
        },
      }),
      transformResponse: (response: IResponseData<IProjectsListItem[]>) => response.data,
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          projectsApi.util.updateQueryData('getProjectsList', null, () => data),
        );
      },
    }),
    getProjectDetails: builder.query<IProject, number>({
      query: (body) => ({
        url: `/details/${body}`,
      }),
      providesTags: (result) => [{ type: 'Project', id: result?.id }],
      transformResponse: (response: IResponseData<IProject>) => response.data,
    }),
    updateProjectDetails: builder.mutation<IProject, IProject>({
      query: (body) => ({
        url: `/details/${body.id}`,
        method: ReqMethods.PUT,
        body: {
          data: {
            name: body.name,
            title: body.title,
            multiplier: body.multiplier,
            elements: body.elements,
          },
        },
      }),
      transformResponse: (response: IResponseData<IProject>) => response.data,
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          projectsApi.util.updateQueryData('getProjectDetails', data.id, () => data),
        );
      },
    }),
    createProject: builder.mutation<IProject, ICreateProjectData>({
      query: (body) => ({
        url: '',
        method: ReqMethods.POST,
        body: {
          data: {
            name: body.name,
            title: body.title,
          },
        },
      }),
      invalidatesTags: (result) => (result ? ['ProjectsList'] : []),
      transformResponse: (response: IResponseData<IProject>) => response.data,
    }),
    deleteProject: builder.mutation<IProject, string | number>({
      query: (id) => ({
        url: `/${id}`,
        method: ReqMethods.DELETE,
      }),
      invalidatesTags: (result) => (result ? ['ProjectsList'] : []),
      transformResponse: (response: IResponseData<IProject>) => response.data,
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetProjectsListQuery,
  useLazyGetProjectsListQuery,
  useGetProjectDetailsQuery,
  useLazyGetProjectDetailsQuery,
  useUpdateProjectDetailsMutation,
  useDeleteProjectMutation,
  useUpdateProjectsListMutation,
} = projectsApi;

export default projectsApi;

import { FC, FormEventHandler, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../../components/Input/HookFormInput';
import { HookFormInputTypes } from '../../components/Input/interfaces/IHookFormInput';
import Button from '../../components/Button/Button';
import AuthForm from '../../components/AuthForm/AuthForm';
import { IAuthFooterLink } from '../../components/AuthForm/interfaces/IAuthFrom';
import { ISignInFormData } from '../../interfaces/formsData';
import { useActions, useEvent } from '../../utils/hooks';
import { signInFormValidation } from '../../utils/validations';
import { emptyFunc } from '../../utils/general';
import { useSignInMutation } from '../../apis/authApi/authApi';
import { localRemoveAuthData } from '../../utils/localStore';
import { PathRoutes } from '../../constants/defines';
import styles from './styles/SignInView.module.scss';

const SignInView: FC = () => {
  const { t } = useTranslation();
  const [signInTrigger, { error, isLoading }] = useSignInMutation();
  const { authSetAuthData } = useActions();

  const {
    register,
    formState: {
      errors,
      touchedFields,
      isValid,
      isSubmitting,
    },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(signInFormValidation),
    mode: 'onChange',
  });

  const email = register('email', { required: true });
  const password = register('password', { required: true });

  const footerLinks = useMemo<IAuthFooterLink[]>(() => [
    {
      title: t('signUp'),
      route: PathRoutes.SIGNUP,
    },
    {
      title: t('forgotPassword'),
      route: PathRoutes.FORGOT_PASSWORD,
    },
    {
      title: t('sendConfirmationEmail'),
      route: PathRoutes.SEND_EMAIL_CONFIRMATION,
    },
  ], [t]);

  const onSubmit = useEvent((formData: ISignInFormData) => signInTrigger(formData).unwrap()
    .then((data) => {
      authSetAuthData(data);
    })
    .catch(async () => {
      authSetAuthData(null);
      await localRemoveAuthData().catch(emptyFunc);
    }));

  return (
    <div className={styles.container}>
      <AuthForm
        title={t('signIn')}
        footerLinks={footerLinks}
        message={error?.message}
        isSuccessMessage={false}
        isLoading={isLoading}
      >
        <form
          id="AuthSignInForm"
          onSubmit={handleSubmit(onSubmit) as FormEventHandler<HTMLFormElement>}
          className={styles.content}
          autoComplete="on"
        >
          <HookFormInput
            placeholder={t('email')}
            ref={email.ref}
            name={email.name}
            onChange={email.onChange}
            onBlur={email.onBlur}
            autoComplete="username email"
            required={email.required}
            error={!isValid && touchedFields.email && errors.email?.message}
          />
          <HookFormInput
            placeholder={t('password')}
            ref={password.ref}
            name={password.name}
            onChange={password.onChange}
            onBlur={password.onBlur}
            type={HookFormInputTypes.PASSWORD}
            autoComplete="password current-password"
            required={password.required}
            error={!isValid && touchedFields.password && errors.password?.message}
          />
          <div className={styles.buttonContainer}>
            <Button
              title={t('signIn')}
              disabled={!isValid || isSubmitting}
              customClass={styles.button}
              onPress={emptyFunc}
              isSubmit
            />
          </div>
        </form>
      </AuthForm>
    </div>
  );
};

export default SignInView;

import {
  DraggableLocation, DropResult,
} from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { DataPrefix, ElementTypes } from '../constants/defines';
import { IElement, IProjectElement } from '../interfaces/general';
import {
  checkIsFolder, convertElementToProjectElement, checkAndChangeOrderInProjectElementsData, parseDragDropUid,
} from './projectUtils';

const reorder = (
  list: (IElement | IProjectElement)[],
  sourceListId: string,
  isElement: boolean,
  startIndex: number,
  endIndex: number,
) => {
  let listByFolder = isElement
    ? (list as IElement[]).filter(({ folderId }) => (folderId === parseDragDropUid(sourceListId).id
      || (!folderId && parseDragDropUid(sourceListId).type === DataPrefix.ELEMENT_ROOT_FOLDER)))
    : (list as IProjectElement[]).filter(({ parentElementUid }) => (
      parseDragDropUid(sourceListId).type === DataPrefix.PROJECT
        ? !parentElementUid
        : parentElementUid === sourceListId));

  const listWithoutFolder = isElement
    ? (list as IElement[])
      .filter(({ folderId }) => (folderId !== parseDragDropUid(sourceListId).id
        && (!folderId && parseDragDropUid(sourceListId).type !== DataPrefix.ELEMENT_ROOT_FOLDER)))
    : (list as IProjectElement[])
      .filter(({ parentElementUid }) => (
        parseDragDropUid(sourceListId).type === DataPrefix.PROJECT
          ? parentElementUid
          : parentElementUid !== sourceListId));

  const [removed] = listByFolder.splice(startIndex, 1);
  (listByFolder as (IElement | IProjectElement)[]).splice(endIndex, 0, removed);

  if (sourceListId.startsWith(DataPrefix.PROJECT_ELEMENT)) {
    listByFolder = checkAndChangeOrderInProjectElementsData(listByFolder as IProjectElement[]);
  }

  return [...listWithoutFolder, ...listByFolder];
};

const move = (
  projectData: IProjectElement[],
  droppableDestination: DraggableLocation,
  sourceIsElement: boolean,
  sourceItem: IElement | IProjectElement,
  onChangeProjectData: (projectData: IProjectElement[]) => void,
) => {
  if (sourceIsElement && !checkIsFolder(sourceItem as IElement)
    && droppableDestination.droppableId.startsWith(DataPrefix.PROJECT)) {
    const projectListClone = Array.from(projectData);
    projectListClone.splice(droppableDestination.index, 0, convertElementToProjectElement(sourceItem as IElement));
    onChangeProjectData(projectListClone);
  }
};

const combine = (
  sourceList: IElement[],
  destList: (IElement | IProjectElement)[],
  sourceItem: IElement | IProjectElement,
  combineWithId: string,
  updateElement: (element: IElement) => void,
  onChangeProjectData: (projectData: IProjectElement[]) => void,
) => {
  if ((sourceItem as IElement).type && combineWithId.startsWith(DataPrefix.ELEMENT)) {
    const destisFolder = sourceList
      .some((item) => item.id === parseDragDropUid(combineWithId).id && checkIsFolder(item));

    if (destisFolder) {
      updateElement({
        ...sourceItem as IElement,
        folderId: parseDragDropUid(combineWithId).id,
      });
    } else {
      toast.warn('It\'s not a folder');
    }
  } else if ((sourceItem as IElement).type === ElementTypes.ITEM && combineWithId.startsWith(DataPrefix.PROJECT)) {
    onChangeProjectData([
      ...destList as IProjectElement[],
      convertElementToProjectElement(sourceItem as IElement, combineWithId),
    ]);
  }
};

export const onDragEnd = (
  elementsData: IElement[],
  projectData: IProjectElement[],
  result: DropResult,
  updateElement: (element: IElement) => void,
  updateFolderList: (componentsList: IElement[]) => void,
  onChangeProjectData: (projectData: IProjectElement[]) => void,
) => {
  const {
    source, destination, draggableId, combine: combineResult,
  } = result;

  const sourceListId = source.droppableId;
  const destListId = destination?.droppableId;

  const sourceListIsElement = sourceListId.startsWith(DataPrefix.ELEMENT);

  const sourceItem = sourceListIsElement
    ? elementsData.find(({ id }) => id === parseDragDropUid(draggableId).id) as IElement
    : projectData.find(({ uid }) => uid === draggableId) as IProjectElement;

  if (combineResult) {
    combine(
      elementsData,
      combineResult.draggableId.startsWith(DataPrefix.ELEMENT) ? elementsData : projectData,
      sourceItem,
      combineResult.draggableId,
      updateElement,
      onChangeProjectData,
    );
    return;
  }

  if (!destination) {
    return;
  }

  if (sourceListId === destListId) {
    const items = reorder(
      sourceListIsElement ? elementsData : projectData,
      sourceListId,
      sourceListIsElement,
      source.index,
      destination.index,
    );
    if (sourceListIsElement) {
      if (JSON.stringify(elementsData) !== JSON.stringify(items)) {
        updateFolderList(items as IElement[]);
      }
    } else {
      onChangeProjectData(items as IProjectElement[]);
    }
    return;
  }

  move(
    projectData,
    destination,
    sourceListIsElement,
    sourceItem,
    onChangeProjectData,
  );
};

export default { onDragEnd };

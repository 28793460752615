import {
  FC, memo, useEffect, useMemo, useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { IProjectView } from '../interfaces/IProjectView';
import ProjectElements from '../../../components/ProjectElements/ProjectElements';
import Loader from '../../../components/Loader/Loader';
import ProjectDetails from '../../../components/ProjectDetails/ProjectDetails';
import ProjectHeader from '../../../components/ProjectHeader/ProjectHeader';
import ProjectFooter from '../../../components/ProjectFooter/ProjectFooter';
import { IProjectElement } from '../../../interfaces/general';
import { useActions, useAppSelector, useEvent } from '../../../utils/hooks';
import { getProjectsWithUpdatedProject, checkAndChangeOrderInProjectElementsData, getProjectElementsDataWithDuplicatedElement } from '../../../utils/projectUtils';
import projectsApi, { useUpdateProjectDetailsMutation } from '../../../apis/projectsApi/projectsApi';
import { emptyFunc } from '../../../utils/general';
import { ProjectElementMenuActionTypes, ProjectViewTargets } from '../../../constants/defines';
import styles from '../styles/ProjectView.module.scss';

const ProjectView: FC<IProjectView> = ({
  onChangeTarget, target,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const user = useAppSelector((state) => state.auth.authData?.user, shallowEqual);
  const draftedProjects = useAppSelector((state) => state.projects.draftedProjects, shallowEqual);
  const currentProjectId = useAppSelector((state) => state.projects.currentProjectId, shallowEqual);
  const projectsTabs = useAppSelector((state) => state.projects.projectsTabs, shallowEqual);
  const isVisibleElementsMenu = useAppSelector((state) => state.projects.isVisibleElementsMenu, shallowEqual);
  const {
    projectsSetActiveModalProjectElementFormData,
    projectsSetDraftedProjects,
    projectsSetActiveModalProjectFormData,
    projectSetModalDeleteId,
    projectSetVisibleElementsMenu,
    projectsSetProjectsTabs,
  } = useActions();
  const [
    updateProjectDetailsTrigger,
    { isLoading },
  ] = useUpdateProjectDetailsMutation({ fixedCacheKey: String(currentProjectId || '') });
  const draftedProject = draftedProjects.find((item) => item.id === currentProjectId);
  const { data, isFetching } = projectsApi.endpoints.getProjectDetails
    .useQueryState(currentProjectId || 0);

  const projectDetails = draftedProject || data;

  const saveProject = useEvent(() => {
    if (projectDetails) {
      updateProjectDetailsTrigger(projectDetails).unwrap()
        .then((res) => {
          if (projectsTabs.some((item) => item.id === res.id)) {
            projectsSetProjectsTabs(projectsTabs.map((item) => {
              if (item.id === res.id) {
                return {
                  id: res.id,
                  name: res.name,
                };
              }
              return item;
            }));
          }
          if (draftedProjects.some((item) => item.id === res.id)) {
            projectsSetDraftedProjects(draftedProjects.filter((item) => item.id !== res.id));
          }
        })
        .catch(emptyFunc);
    }
  });

  const print = useReactToPrint({
    content: () => ref.current,
    documentTitle: `${projectDetails?.title || ''} - ${t('estimate')}`,
    onBeforeGetContent: async () => {
      projectSetVisibleElementsMenu(false);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });
    },
    onAfterPrint: () => {
      projectSetVisibleElementsMenu(true);
    },
  });

  useEffect(() => {
    const onPrintSave = (e: KeyboardEvent) => {
      if (e.code === 'KeyS' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        saveProject();
      }

      if (e.code === 'KeyP' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        print();
      }
    };
    window.addEventListener('keydown', onPrintSave);
    return () => {
      window.removeEventListener('keydown', onPrintSave);
    };
  }, [saveProject, print]);

  const changeItemOrder = useEvent((uid: string, order: number) => {
    if (projectDetails) {
      const updatedDraftedProjects = getProjectsWithUpdatedProject(
        draftedProjects,
        {
          ...projectDetails,
          elements: checkAndChangeOrderInProjectElementsData(
            projectDetails.elements.map((item) => {
              if (item.uid === uid) {
                return { ...item, order };
              }
              return item;
            }),
          ),
        },
      );
      projectsSetDraftedProjects(updatedDraftedProjects);
    }
  });

  const menuAction = useEvent((type: ProjectElementMenuActionTypes, element: IProjectElement) => {
    if (projectDetails) {
      switch (type) {
        case ProjectElementMenuActionTypes.EDIT:
          projectsSetActiveModalProjectElementFormData({
            uid: element.uid,
            title: element.title,
            description: element.description,
            value: String(element.value),
          });
          break;
        case ProjectElementMenuActionTypes.DELETE: {
          if (!element.parentElementUid
            && projectDetails.elements.some((item) => item.parentElementUid === element.uid)) {
            projectSetModalDeleteId(element.uid);
          } else {
            const updatedDraftedProjects = getProjectsWithUpdatedProject(
              draftedProjects,
              {
                ...projectDetails,
                elements: checkAndChangeOrderInProjectElementsData(
                  projectDetails.elements.filter((item) => item.uid !== element.uid),
                ),
              },
            );
            projectsSetDraftedProjects(updatedDraftedProjects);
          }
          break;
        }
        case ProjectElementMenuActionTypes.DUPLICATE: {
          const updatedDraftedProjects = getProjectsWithUpdatedProject(
            draftedProjects,
            {
              ...projectDetails,
              elements: getProjectElementsDataWithDuplicatedElement(projectDetails.elements, element),
            },
          );
          projectsSetDraftedProjects(updatedDraftedProjects);
          break;
        }
        default:
          break;
      }
    }
  });

  const setMultiplier = useEvent((value: number) => {
    if (projectDetails) {
      const updatedDraftedProjects = getProjectsWithUpdatedProject(
        draftedProjects,
        {
          ...projectDetails,
          multiplier: value,
        },
      );
      projectsSetDraftedProjects(updatedDraftedProjects);
    }
  });

  const menuOptions = useMemo(() => [
    {
      id: 'edit',
      title: t('edit'),
      onPress: () => {
        projectsSetActiveModalProjectFormData({
          id: projectDetails?.id || 0,
          name: projectDetails?.name || '',
          title: projectDetails?.title || '',
        });
      },
    },
    {
      id: 'delete',
      title: t('delete'),
      onPress: () => {
        projectSetModalDeleteId(projectDetails?.id || '');
      },
    },
  ], [
    t,
    projectDetails,
    projectsSetActiveModalProjectFormData,
    projectSetModalDeleteId,
  ]);

  return (!projectDetails || isLoading || isFetching) ? (
    <Loader customClass={styles.loader} />
  ) : (
    <>
      <ProjectDetails
        name={projectDetails.name}
        isVisibleSaveButton={!!draftedProjects.some((item) => item.id === projectDetails.id)}
        onSave={saveProject}
        multiplier={projectDetails.multiplier}
        onChangeMultiplier={setMultiplier}
        menuOptions={menuOptions}
        onPrint={print}
        isLoading={isLoading}
      />
      <div className={styles.projectContainer}>
        <div className={styles.content} ref={ref}>
          <ProjectHeader
            projectTitle={projectDetails.title}
            authorNameName={`${user?.firstName || ''} ${user?.lastName || ''}`}
            dateString={projectDetails.createdAt}
          />
          <ProjectElements
            data={projectDetails.elements}
            onChangeTarget={onChangeTarget}
            type={target === ProjectViewTargets.PROJECT ? ProjectViewTargets.PROJECT : undefined}
            onChangeOrder={changeItemOrder}
            menuAction={menuAction}
            multiplier={projectDetails.multiplier}
            isMenuVisible={isVisibleElementsMenu}
          />
          <ProjectFooter project={projectDetails} />
        </div>
        <div className={styles.spacer} />
      </div>
    </>
  );
};

export default memo(ProjectView);

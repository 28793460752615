import { FC, memo, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import PathBar from '../../../components/PathBar/PathBar';
import HeaderTabs from '../../../components/HeaderTabs/HeaderTabs';
import { IUser } from '../../../interfaces/general';
import { useActions, useAppSelector, useEvent } from '../../../utils/hooks';
import { getTabIdAfterCloseTab } from '../../../utils/projectUtils';
import styles from '../styles/Header.module.scss';

const Header: FC = () => {
  const user = useAppSelector((state) => state.auth.authData?.user, shallowEqual);
  const path = useAppSelector((state) => state.elements.folderPath, shallowEqual);
  const {
    projectsTabs,
    currentProjectId,
    draftedProjects,
  } = useAppSelector((state) => state.projects, shallowEqual);
  const {
    elementsSetFolderPath,
    projectsSetProjectsTabs,
    projectsSetCurrentProjectId,
    projectsSetModalCloseId,
    authLogout,
  } = useActions();

  const draftedProjectsIds = useMemo(() => draftedProjects.map(({ id }) => id), [draftedProjects]);

  const closeProject = useEvent((id: number | null) => {
    projectsSetCurrentProjectId(getTabIdAfterCloseTab(id as number, currentProjectId, projectsTabs));
    projectsSetProjectsTabs(projectsTabs.filter((item) => item.id !== id));
  });

  const checkCloseOpenedProject = useEvent((id: number) => {
    if (draftedProjectsIds.some((item) => id === item)) {
      projectsSetModalCloseId(id);
    } else {
      closeProject(id);
    }
  });

  const backToPreviousFolderPath = useEvent(() => {
    const newPathArr = [...path];
    newPathArr.pop();
    elementsSetFolderPath(newPathArr);
  });

  const selectFolder = useEvent((id: number | null) => {
    const newPathArr = [...path];
    newPathArr.splice(
      path.findIndex((item) => item.id === id) + 1,
      path.length,
    );
    elementsSetFolderPath(newPathArr);
  });

  const openMenu = useEvent(() => {
    projectsSetCurrentProjectId(null);
  });

  return (
    <div className={styles.container}>
      <HeaderTabs
        data={projectsTabs}
        setData={projectsSetProjectsTabs}
        openMenu={openMenu}
        activeTabId={currentProjectId}
        onTabClick={projectsSetCurrentProjectId}
        onClose={checkCloseOpenedProject}
        draftedProjectsIds={draftedProjectsIds}
        onLogout={authLogout}
        user={user as IUser}
      />
      <PathBar data={path} onBack={backToPreviousFolderPath} onFolderPress={selectFolder} />
    </div>
  );
};

export default memo(Header);

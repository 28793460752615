import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../constants/general';
import { ReqMethods } from '../../constants/defines';
import { prepareAuthHeaders } from '../../utils/request';
import { IElement, IFolderList } from '../../interfaces/general';
import { IResponseData } from '../../interfaces/responses';

const elementsApi = createApi({
  reducerPath: 'elementsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/elements`,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['ElementsList'],
  endpoints: (builder) => ({
    getElementsFolderList: builder.query<IFolderList, number | null>({
      query: (folderId) => ({
        url: `/list/${folderId || ''}`,
      }),
      transformResponse: (response: IResponseData<IFolderList>) => response.data,
      providesTags: ['ElementsList'],
    }),
    updateElementsFolderList: builder.mutation<IFolderList, { folderId: number | null, elements: number[] }>({
      query: (body) => ({
        url: `/list/${body.folderId || ''}`,
        method: ReqMethods.PUT,
        body: {
          data: body.elements,
        },
      }),
      transformResponse: (response: IResponseData<IFolderList>) => response.data,
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          elementsApi.util.updateQueryData('getElementsFolderList', data.folderInfo?.id || null, () => data),
        );
      },
    }),
    createElement: builder.mutation<IElement, IElement>({
      query: (body) => ({
        url: '/',
        method: ReqMethods.POST,
        body: {
          data: body,
        },
      }),
      transformResponse: (response: IResponseData<IElement>) => response.data,
      invalidatesTags: (result) => (result ? ['ElementsList'] : []),
    }),
    deleteElement: builder.mutation<IElement, string | number>({
      query: (id) => ({
        url: `/${id}`,
        method: ReqMethods.DELETE,
      }),
      transformResponse: (response: IResponseData<IElement>) => response.data,
      invalidatesTags: (result) => (result ? ['ElementsList'] : []),
    }),
    updateElement: builder.mutation<IElement, IElement>({
      query: (body) => ({
        url: `/${body.id}`,
        method: ReqMethods.PUT,
        body: {
          data: body,
        },
      }),
      transformResponse: (response: IResponseData<IElement>) => response.data,
      invalidatesTags: (result) => (result ? ['ElementsList'] : []),
    }),
  }),
});

export const {
  useCreateElementMutation,
  useDeleteElementMutation,
  useUpdateElementMutation,
  useGetElementsFolderListQuery,
  useLazyGetElementsFolderListQuery,
  useUpdateElementsFolderListMutation,
} = elementsApi;

export default elementsApi;

import { FC, useEffect } from 'react';
import { IS_ELECTRON } from './constants/general';
import WindowTitleBar from './components/WindowTitleBar/WindowTitleBar';
import { useInit } from './utils/hooks';
import AppRouter from './router/AppRouter';

const App: FC = () => {
  const isLoading = useInit();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--titleBarHeight',
      IS_ELECTRON && window.electron?.electronPlatform === 'win32' ? '32px' : '0px',
    );
  }, []);

  return (
    <div className="appContainer">
      {IS_ELECTRON && window.electron?.electronPlatform === 'win32' && (
        <WindowTitleBar />
      )}
      {isLoading ? null : (
        <div className="appContent">
          <AppRouter />
        </div>
      )}
    </div>
  );
};

export default App;

import { CSSProperties } from 'react';
import {
  DraggableStateSnapshot,
  DraggingStyle,
  DroppableStateSnapshot,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

export const getElementsListStyle = (
  droppableSnapshot: DroppableStateSnapshot,
): CSSProperties => ({
  background: droppableSnapshot.isDraggingOver ? '#eeeeee' : 'white',
});

export const getProjectElementsListStyle = (droppableSnapshot: DroppableStateSnapshot): CSSProperties => ({
  background: droppableSnapshot.isDraggingOver ? '#599ff32b' : 'transparent',
});

export const getProjectsListStyle = (
  droppableSnapshot: DroppableStateSnapshot,
): CSSProperties => ({
  background: droppableSnapshot.isDraggingOver ? '#599ff32b' : 'transparent',
});

export const getElementStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle?: DraggingStyle | NotDraggingStyle,
  isCutElement?: boolean,
): CSSProperties => {
  const defaultBackground = isCutElement ? '#d9d9d9' : 'white';
  return {
    background: snapshot.isDragging ? '#d7dbe2' : defaultBackground,
    ...draggableStyle,
    borderColor: snapshot.isDragging ? '#d7dbe2' : undefined,
    opacity: snapshot.isDragging ? 0.9 : 1,
  };
};

export const getChildProjectElementsItemStyle = (
  order: number,
  snapshot: DraggableStateSnapshot,
  draggableStyle?: DraggingStyle | NotDraggingStyle,
): CSSProperties => ({
  background: snapshot.isDragging ? '#E2E6ED' : 'transparent',
  ...draggableStyle,
  opacity: snapshot.isDragging ? 0.9 : 1,
  paddingLeft: (order - 1) * 20,
});

export const getHeaderTabStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle?: DraggingStyle | NotDraggingStyle,
): CSSProperties => ({
  borderLeft: snapshot.isDragging ? '1px solid #599ff3' : '1px solid transparent',
  borderRight: snapshot.isDragging ? '1px solid #599ff3' : '1px solid transparent',
  borderBottom: snapshot.isDragging ? '2px solid #599ff3' : undefined,
  ...draggableStyle,
  opacity: snapshot.isDragging ? 0.9 : 1,
});

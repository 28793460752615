import { FC, FormEventHandler, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import HookFormInput from '../../components/Input/HookFormInput';
import Button from '../../components/Button/Button';
import AuthForm from '../../components/AuthForm/AuthForm';
import { IAuthFooterLink } from '../../components/AuthForm/interfaces/IAuthFrom';
import { ISendEmailConfirmationFormData } from '../../interfaces/formsData';
import { useEvent } from '../../utils/hooks';
import { sendEmailConfirmationFormValidation } from '../../utils/validations';
import { emptyFunc } from '../../utils/general';
import { useSendEmailConfirmationMutation } from '../../apis/authApi/authApi';
import { PathRoutes } from '../../constants/defines';
import styles from './styles/SendConfirmationEmailView.module.scss';

const SendConfirmationEmailView: FC = () => {
  const { t } = useTranslation();
  const [sendEmailConfirmationTrigger, { error, isLoading, isSuccess }] = useSendEmailConfirmationMutation();

  const {
    register,
    formState: {
      errors,
      touchedFields,
      isValid,
      isSubmitting,
    },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(sendEmailConfirmationFormValidation),
    mode: 'onChange',
  });

  const email = register('email', { required: true });

  const footerLinks = useMemo<IAuthFooterLink[]>(() => [
    {
      title: t('signIn'),
      route: PathRoutes.SIGNIN,
    },
  ], [t]);

  const onSubmit = useEvent((formData: ISendEmailConfirmationFormData) => sendEmailConfirmationTrigger(formData)
    .unwrap()
    .then(() => {
      reset();
    })
    .catch(emptyFunc));

  return (
    <div className={styles.container}>
      <AuthForm
        title={t('sendConfirmationEmail')}
        footerLinks={footerLinks}
        message={error?.message || (isSuccess ? t('confirmationEmailWasSent') : undefined)}
        isSuccessMessage={!error}
        isLoading={isLoading}
      >
        <form
          id="SendEmailVerificationForm"
          onSubmit={handleSubmit(onSubmit) as FormEventHandler<HTMLFormElement>}
          className={styles.content}
          autoComplete="on"
        >
          <HookFormInput
            placeholder={t('email')}
            ref={email.ref}
            name={email.name}
            onChange={email.onChange}
            onBlur={email.onBlur}
            autoComplete="username email"
            required={email.required}
            error={!isValid && touchedFields.email && errors.email?.message}
          />
          <div className={styles.buttonContainer}>
            <Button
              title={t('sendConfirmationEmail')}
              disabled={!isValid || isSubmitting}
              customClass={styles.button}
              onPress={emptyFunc}
              isSubmit
            />
          </div>
        </form>
      </AuthForm>
    </div>
  );
};

export default SendConfirmationEmailView;

import { FC, memo } from 'react';
import { IErrorView } from '../interfaces/IErrorView';
import styles from '../styles/ErrorView.module.scss';

const ErrorView: FC<IErrorView> = ({ message }) => (
  <div className={styles.container}>
    <span>{message}</span>
  </div>
);

export default memo(ErrorView);

import { FC, memo } from 'react';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import { IFolderDetails } from './interfaces/IFolderDetails';
import styles from './styles/FolderDetails.module.scss';

const FolderDetails: FC<IFolderDetails> = ({ name, menuOptions }) => {
  return (
    <div className={styles.container}>
      <span className={styles.name}>
        {name}
      </span>
      <DropDownMenu actions={menuOptions} />
    </div>
  );
};

export default memo(FolderDetails);

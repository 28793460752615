import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IProjectHeader } from './interfaces/IProjectHeader';
import styles from './styles/ProjectHeader.module.scss';

const ProjectHeader: FC<IProjectHeader> = ({
  dateString,
  authorNameName,
  projectTitle,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.column}>
          <span>{t('poweredBy', { authorNameName })}</span>
        </div>
        <div className={styles.column}>
          <span>{t('project', { projectTitle })}</span>
          <span>
            {t(
              'date',
              {
                date: new Date(dateString)
                  .toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
              },
            )}
          </span>
        </div>
      </div>
      <div className={styles.tableHeaderRow}>
        <div className={styles.orderContainer}>
          <span className={styles.order}>#</span>
        </div>
        <div className={styles.tasksContainer}>
          <span className={styles.tasks}>{t('tasks')}</span>
        </div>
        <div className={styles.hoursContainer}>
          <span className={styles.hours}>{t('hours')}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(ProjectHeader);

import { createSlice } from '@reduxjs/toolkit';
import { IProjectsState } from './interfaces/IProjectsState';
import {
  IAction,
  IProject,
  IProjectFormData,
  IProjectElementFormData,
  IProjectsListItem,
} from '../../interfaces/general';

export const initialState: IProjectsState = {
  projectsTabs: [],
  currentProjectId: null,
  draftedProjects: [],
  activeModalProjectFormData: null,
  activeModalProjectElementFormData: null,
  modalDeleteId: '',
  modalCloseId: null,
  isVisibleElementsMenu: true,
  isNewProject: false,
};

export const projects = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    projectsSetProjectsTabs: (state, { payload }: IAction<IProjectsListItem[]>) => {
      state.projectsTabs = payload;
    },
    projectsSetCurrentProjectId: (state, { payload }: IAction<number | null>) => {
      state.currentProjectId = payload;
    },
    projectsSetDraftedProjects: (state, { payload }: IAction<IProject[]>) => {
      state.draftedProjects = payload;
    },
    projectsSetActiveModalProjectFormData: (state, { payload }: IAction<null | IProjectFormData>) => {
      state.activeModalProjectFormData = payload;
    },
    projectsSetActiveModalProjectElementFormData: (state, { payload }: IAction<null | IProjectElementFormData>) => {
      state.activeModalProjectElementFormData = payload;
    },
    projectsSetModalCloseId: (state, { payload }: IAction<number | null>) => {
      state.modalCloseId = payload;
    },
    projectSetModalDeleteId: (state, { payload }: IAction<string | number>) => {
      state.modalDeleteId = payload;
    },
    projectSetVisibleElementsMenu: (state, { payload }: IAction<boolean>) => {
      state.isVisibleElementsMenu = payload;
    },
    projectSetNewProject: (state, { payload }: IAction<boolean>) => {
      state.isNewProject = payload;
    },
  },
});

export const projectsActions = projects.actions;

export default projects.reducer;

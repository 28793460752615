export enum DataPrefix {
  ELEMENT = 'ELEMENT',
  ELEMENTS_ITEM = 'ELEMENTS_ITEM',
  ELEMENTS_FOLDER = 'ELEMENTS_FOLDER',
  ELEMENT_ROOT_FOLDER = 'ELEMENT_ROOT_FOLDER',
  PROJECT_ELEMENT = 'PROJECT_ELEMENT',
  PROJECT = 'PROJECT',
}

export enum ProjectViewTargets {
  FOLDER = 'FOLDER',
  PROJECT = 'PROJECT',
  ELEMENT = 'ELEMENT',
}

export enum ElementMenuActionTypes {
  CUT = 'cut',
  COPY = 'copy',
  DUPLICATE = 'duplicate',
  EDIT = 'edit',
  DELETE = 'delete',
  RENAME = 'rename',
}

export enum ProjectElementMenuActionTypes {
  EDIT = 'edit',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}

export enum LocalSaveKeys {
  PROJECTS_LIST_ORDER = 'projects-list-order',
  ELEMENTS = 'elements',
  OPENED_PROJECTS = 'opened-projects',
  AUTH = 'auth-project-estimation',
}

export enum ImgStates {
  IDLE = 'IDLE',
  HOVER = 'HOVER',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}
export enum PathRoutes {
  HOME = '/',
  SEND_EMAIL_CONFIRMATION = '/send-email-confirmation',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  SIGNUP = '/signup',
  SIGNIN = '/signin',
}

export enum ReqMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum ElementTypes {
  FOLDER = 'FOLDER',
  ITEM = 'ITEM',
}

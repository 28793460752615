import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ChildProjectElementDroppable from './ChildProjectElementDroppable';
import { IProjectElementsItem } from '../interfaces/IProjectElementsItem';
import { getElementStyle } from '../../../utils/getStyles';
import { ProjectElementMenuActionTypes, ProjectViewTargets } from '../../../constants/defines';
import styles from '../styles/ProjectElementsItem.module.scss';
import { cn, toAutomateFixed, toNumber } from '../../../utils/general';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';

const ProjectElementsItem: FC<IProjectElementsItem> = ({
  data,
  provided,
  onChangeTarget,
  snapshot,
  index,
  childData,
  onChangeOrder,
  menuAction,
  multiplier = 1,
  isMenuVisible,
}) => {
  const { t } = useTranslation();
  const totalEstimateHours = useMemo(() => (
    childData.reduce(
      (acc, elem) => acc + toNumber(toAutomateFixed(elem.value * multiplier)),
      toNumber(toAutomateFixed(data.value * multiplier)),
    )), [childData, data.value, multiplier]);

  const menuOptions = useMemo(() => [
    {
      id: 'edit',
      title: t('edit'),
      onPress: () => {
        menuAction(ProjectElementMenuActionTypes.EDIT, data);
      },
    },
    {
      id: 'duplicate',
      title: t('duplicate'),
      onPress: () => {
        menuAction(ProjectElementMenuActionTypes.DUPLICATE, data);
      },
    },
    {
      id: 'delete',
      title: t('delete'),
      onPress: () => {
        menuAction(ProjectElementMenuActionTypes.DELETE, data);
      },
    },
  ], [t, data, menuAction]);

  return (
    <div
      ref={provided.innerRef}
      data-rbd-draggable-context-id={provided.draggableProps['data-rbd-draggable-context-id']}
      data-rbd-draggable-id={provided.draggableProps['data-rbd-draggable-id']}
      onTransitionEnd={provided.draggableProps.onTransitionEnd}
      aria-describedby={provided.dragHandleProps?.['aria-describedby']}
      data-rbd-drag-handle-context-id={provided.dragHandleProps?.['data-rbd-drag-handle-context-id']}
      data-rbd-drag-handle-draggable-id={provided.dragHandleProps?.['data-rbd-drag-handle-draggable-id']}
      draggable={provided.dragHandleProps?.draggable}
      onDragStart={provided.dragHandleProps?.onDragStart}
      role="button"
      tabIndex={provided.dragHandleProps?.tabIndex}
      onMouseDown={() => {
        onChangeTarget(ProjectViewTargets.PROJECT);
      }}
      onTouchStart={() => {
        onChangeTarget(ProjectViewTargets.PROJECT);
      }}
      style={getElementStyle(
        snapshot,
        provided.draggableProps.style,
      )}
      className={styles.container}
    >
      <div className={styles.order}>
        <span>{index + 1}</span>
      </div>
      <div className={cn(styles.content, !!childData.length && styles.contentWithDroppable)}>
        <div className={styles.info}>
          <div className={styles.header}>
            <span className={styles.title}>{data.title}</span>
            <span className={styles.estimate}>{`(${toAutomateFixed(data.value * multiplier)} h)`}</span>
            <DropDownMenu customClass={styles.actions} actions={menuOptions} disabled={!isMenuVisible} />
          </div>
          <p className={styles.description}>{data.description}</p>
        </div>
        {childData.length > 0 && (
          <ChildProjectElementDroppable
            data={childData}
            uid={data.uid}
            onChangeOrder={onChangeOrder}
            menuAction={menuAction}
            multiplier={multiplier}
            isMenuVisible={isMenuVisible}
          />
        )}
      </div>
      <div className={styles.estimateHours}>
        <span>{`${toAutomateFixed(totalEstimateHours)} h`}</span>
      </div>
    </div>
  );
};

export default memo(ProjectElementsItem);

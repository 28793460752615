import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IChildProjectElementsItem } from '../interfaces/IChildProjectElementsItem';
import { getChildProjectElementsItemStyle } from '../../../utils/getStyles';
import styles from '../styles/ChildProjectElementsItem.module.scss';
import IconButton from '../../IconButton/IconButton';
import ChevronIcon from '../../../assets/chevronDown.svg';
import { useEvent } from '../../../utils/hooks';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';
import { ProjectElementMenuActionTypes } from '../../../constants/defines';
import { toAutomateFixed } from '../../../utils/general';

const ProjectElementsItem: FC<IChildProjectElementsItem> = ({
  data, provided, snapshot, maxOrder, onChangeOrder, menuAction, multiplier = 1, isMenuVisible,
}) => {
  const { t } = useTranslation();
  const increaseOrder = useEvent(() => {
    onChangeOrder(data.uid, data.order < maxOrder ? data.order + 1 : maxOrder);
  });

  const decreaseOrder = useEvent(() => {
    onChangeOrder(data.uid, data.order > 1 ? data.order - 1 : 1);
  });

  const menuOptions = useMemo(() => [
    {
      id: 'edit',
      title: t('edit'),
      onPress: () => {
        menuAction(ProjectElementMenuActionTypes.EDIT, data);
      },
    },
    {
      id: 'duplicate',
      title: t('duplicate'),
      onPress: () => {
        menuAction(ProjectElementMenuActionTypes.DUPLICATE, data);
      },
    },
    {
      id: 'delete',
      title: t('delete'),
      onPress: () => {
        menuAction(ProjectElementMenuActionTypes.DELETE, data);
      },
    },
  ], [t, data, menuAction]);

  return (
    <div
      ref={provided.innerRef}
      data-rbd-draggable-context-id={provided.draggableProps['data-rbd-draggable-context-id']}
      data-rbd-draggable-id={provided.draggableProps['data-rbd-draggable-id']}
      onTransitionEnd={provided.draggableProps.onTransitionEnd}
      aria-describedby={provided.dragHandleProps?.['aria-describedby']}
      data-rbd-drag-handle-context-id={provided.dragHandleProps?.['data-rbd-drag-handle-context-id']}
      data-rbd-drag-handle-draggable-id={provided.dragHandleProps?.['data-rbd-drag-handle-draggable-id']}
      draggable={provided.dragHandleProps?.draggable}
      onDragStart={provided.dragHandleProps?.onDragStart}
      role="button"
      tabIndex={provided.dragHandleProps?.tabIndex}
      style={getChildProjectElementsItemStyle(
        data.order,
        snapshot,
        provided.draggableProps.style,
      )}
      className={styles.container}
    >
      <div className={styles.buttons}>
        <IconButton
          isVisible={data.order > 1}
          customClass={styles.decreaseOrder}
          icon={ChevronIcon}
          onPress={decreaseOrder}
          size={20}
        />
        <IconButton
          isVisible={data.order < maxOrder}
          customClass={styles.increaseOrder}
          icon={ChevronIcon}
          onPress={increaseOrder}
          size={20}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.title}>{data.title}</span>
          <span className={styles.estimate}>{`(${toAutomateFixed(data.value * multiplier)} h)`}</span>
          <DropDownMenu customClass={styles.actions} actions={menuOptions} disabled={!isMenuVisible} />
        </div>
        <p className={styles.description}>{data.description}</p>
      </div>
    </div>
  );
};

export default memo(ProjectElementsItem);

import { IAnyObject, IElement } from '../interfaces/general';
import { IApiError, IApiErrorArrayData, IApiErrorStandardData } from '../interfaces/responses';

export const emptyFunc = () => { };

export const cn = (...classes: Array<string | boolean | undefined>): string => {
  const stringArray = classes.reduce((acc: string[], item: string | boolean | undefined): string[] => {
    if (item && item !== true) {
      return [...acc, item];
    }
    return [...acc];
  }, []);
  return stringArray.join(' ');
};

export const getItemByUid = <T>(
  array: (T & { uid: string })[],
  searchId: string,
) => {
  return array.find(({ uid }) => uid === searchId);
};

export const getNameForDuplicate = (defaultName: string, suffix: string, data: IElement[]) => {
  let count = 0;
  const namesArr = data.map((item) => item.name);
  let newName = `${defaultName}-${suffix}`;
  while (namesArr.includes(newName)) {
    count += 1;
    newName = `${defaultName}-${suffix}-${count}`;
  }
  return newName;
};

export const getNewArrWithExcludedLastItem = <T = unknown>(arr: T[]) => {
  const cloneArr = [...arr];
  cloneArr.pop();
  return cloneArr;
};

export const toNoExponentsString = (num: number) => (String(num)).replace(
  /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
  (a: string, b: string, c: string, d: string, e: string) => (
    parseFloat(e) < 0
      ? `${b}0.${Array(1 - parseFloat(e) - c.length).join('0')}${c}${d}`
      : `${b}${c}${d}${Array(parseFloat(e) - d.length + 1).join('0')}`
  ),
);

export const trimValuesInObj = <T = unknown>(obj: IAnyObject): T => {
  const result = {};
  Object.entries(obj).forEach(([key, value]) => {
    Object.defineProperty(result, key, {
      value: typeof value === 'string' ? value.trim() : value,
      configurable: true,
      writable: true,
      enumerable: true,
    });
  });
  return result as T;
};

export const checkIsNoClickPropagation = (target: HTMLElement, currentTarget: HTMLElement) => {
  let node: HTMLElement | null = target;
  while (node) {
    if (node === currentTarget) {
      return true;
    }
    if (node.onclick) {
      return false;
    }
    node = node.parentElement;
  }
  return true;
};

export const toAutomateFixed = (value?: number, fractionalDigits = 2) => {
  if (value) {
    return (value % 1) === 0 ? String(value) : value.toFixed(fractionalDigits);
  }
  return String(0);
};

export const toNumber = (value?: string | number | null) => {
  if (value) {
    const result = typeof value === 'string' ? parseFloat(value) : value;
    return Number.isNaN(result) ? 0 : result;
  }
  return 0;
};

export const getErrorMessage = (
  error?: IApiError,
) => {
  try {
    if ((error as IApiErrorStandardData)?.error?.details?.errors) {
      return (error as IApiErrorStandardData).error.details.errors?.map((item) => item.message).join(' ');
    }
    if ((error as IApiErrorStandardData)?.error?.message) {
      return (error as IApiErrorStandardData)?.error.message || '';
    }
    if ((error as IApiErrorArrayData).message?.[0]?.messages) {
      return (error as IApiErrorArrayData).message[0].messages?.map((item) => item.message).join(' ') || '';
    }
    return 'Error';
  } catch (er) {
    return 'Error';
  }
};

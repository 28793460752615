import { FC, memo } from 'react';
import ModalWindow from '../ModalWindow/ModalWindow';
import ProjectForm from './components/ProjectForm';
import { IModalProjectForm } from './interfaces/IModalProjectForm';

const ModalProjectForm: FC<IModalProjectForm> = ({
  onClose, onSubmit, isVisible, title, initValues,
}) => (
  <ModalWindow title={title} onClose={onClose} isVisible={isVisible}>
    <ProjectForm onSubmit={onSubmit} initValues={initValues} />
  </ModalWindow>
);

export default memo(ModalProjectForm);

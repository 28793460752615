import { v4 as uuidv4 } from 'uuid';
import { DataPrefix, ElementTypes } from '../constants/defines';
import {
  IElement, IProjectElement, IProject, IProjectsListItem,
} from '../interfaces/general';

export const getDragDropUId = (type: DataPrefix, id?: number | string) => (
  `${type}-${id || ''}`
);

export const getProjectElementUid = () => (
  getDragDropUId(DataPrefix.PROJECT_ELEMENT, `${new Date().getTime()}-${uuidv4()}`)
);

export const parseDragDropUid = (uid: string) => {
  const uidArray = uid.split('-');
  return {
    type: uidArray.at(0) as DataPrefix,
    id: uidArray.length === 2 ? parseInt(uidArray.at(1) as string) : null,
  };
};

export const convertElementToProjectElement = (
  element: IElement,
  parentElementUid: null | string = null,
): IProjectElement => ({
  uid: getProjectElementUid(),
  title: element.title || '',
  description: element.description || '',
  value: element.value || 0,
  order: parentElementUid ? 1 : 0,
  parentElementUid,
});

export const checkIsFolder = (data?: IElement) => data?.type === ElementTypes.FOLDER;

export const checkIsProjectElementByUid = (uid?: string) => uid?.startsWith(DataPrefix.PROJECT_ELEMENT);

export const addElementToProject = (
  item: IElement,
  projectData: IProjectElement[],
  onChangeProjectData: (projectData: IProjectElement[]) => void,
) => {
  onChangeProjectData([...projectData, convertElementToProjectElement(item)]);
};

export const getProjectsWithUpdatedProject = (projects: IProject[], updatedProject?: IProject) => {
  if (updatedProject) {
    if (projects.some(({ id }) => id === updatedProject.id)) {
      return projects.map((item) => {
        if (item.id === updatedProject.id) {
          return updatedProject;
        }
        return item;
      });
    }
    return [updatedProject, ...projects];
  }
  return projects;
};

export const checkAndChangeOrderInProjectElementsData = (data: IProjectElement[]) => {
  return data.reduce((acc, item, i) => {
    const maxOrder = i < 1 ? 1 : (acc[i - 1]).order + 1;
    if (item.order > maxOrder) {
      const modifiedItem = {
        ...item,
        order: maxOrder,
      };
      return [...acc, modifiedItem];
    }
    return [...acc, item];
  }, [] as IProjectElement[]);
};

export const getTabIdAfterCloseTab = (
  id: number,
  currentProjectId: number | null,
  openedProjects: IProjectsListItem[],
) => {
  const tabIndex = openedProjects.findIndex((item) => item.id === id);
  if (currentProjectId === id) {
    const nextCurrentProjectId = openedProjects[tabIndex + 1]?.id
      || openedProjects[tabIndex - 1]?.id
      || null;
    return nextCurrentProjectId;
  }
  return currentProjectId;
};

export const getProjectElementsDataWithDuplicatedElement = (
  projectElementsData: IProjectElement[],
  duplicateElement: IProjectElement,
) => {
  const isParent = !duplicateElement.parentElementUid;
  const index = projectElementsData.findIndex(({ uid }) => uid === duplicateElement.uid);
  const result = [...projectElementsData];
  const newElement = {
    ...duplicateElement,
    uid: getProjectElementUid(),
  };

  if (isParent) {
    const duplicatedChildren = result.filter(({ parentElementUid }) => parentElementUid === duplicateElement.uid)
      .map((item) => ({ ...item, uid: getProjectElementUid(), parentElementUid: newElement.uid }))
      .reverse();
    duplicatedChildren.forEach((item) => {
      result.splice(index + 1, 0, item);
    });
    result.splice(index + 1, 0, newElement);
  } else {
    result.splice(index + 1, 0, newElement);
  }
  return result;
};

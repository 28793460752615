import { createSlice } from '@reduxjs/toolkit';
import { IGeneralState } from './interfaces/IGeneralState';
import { ProjectViewTargets } from '../../constants/defines';
import { IAction } from '../../interfaces/general';

export const initialState: IGeneralState = {
  dndTarget: null,
  isLoading: true, // it is initLoader
  generalError: '',
};

export const general = createSlice({
  name: 'general' as const,
  initialState,
  reducers: {
    generalSetDndTarget: (state, { payload }: IAction<ProjectViewTargets | null>) => {
      state.dndTarget = payload;
    },
    generalSetError: (state, { payload }: IAction<string>) => {
      state.generalError = payload;
    },
  },
  extraReducers: () => {},
});

export const generalActions = general.actions;

export default general.reducer;

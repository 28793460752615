import { FC, memo } from 'react';
import styles from './styles/Input.module.scss';
import { IInput } from './interfaces/IInput';
import ErrorText from '../ErrorText/ErrorText';
import { cn } from '../../utils/general';

const Input: FC<IInput> = ({
  value,
  title,
  onChange,
  type,
  placeholder,
  onBlur,
  onFocus,
  error,
  customClassName,
}) => {
  return (
    <div className={cn(styles.container, error && styles.errorContainer, customClassName)}>
      {title && <span className={styles.title}>{title}</span>}
      {type === 'textarea' ? (
        <textarea
          value={value}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          rows={4}
          onChange={(e) => {
            const val = e.target.value;
            onChange(val);
          }}
        />
      ) : (
        <input
          value={value}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={type === 'number' ? 15 : undefined}
          onChange={(e) => {
            let val = e.target.value;
            if (type === 'number') {
              val = val.match(/\d+[.,]?\d{0,2}/)?.[0] || '';
              val = val.replace(/,/g, '.');
            }
            onChange(val);
          }}
        />
      )}
      {error && <ErrorText error={error} />}
    </div>
  );
};

export default memo(Input);

import { combineReducers } from '@reduxjs/toolkit';
import general from './general/slice';
import elements from './elements/slice';
import projects from './projects/slice';
import auth from './auth/slice';
import authApi from '../apis/authApi/authApi';
import projectsApi from '../apis/projectsApi/projectsApi';
import elementsApi from '../apis/elementsApi/elementsApi';

const rootReducer = combineReducers({
  general,
  elements,
  projects,
  auth,
  [authApi.reducerPath]: authApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [elementsApi.reducerPath]: elementsApi.reducer,
});

export type IRootState = ReturnType<typeof rootReducer>;

export default rootReducer;

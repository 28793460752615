import {
  useLayoutEffect, useMemo, useRef, useCallback, useState, useEffect,
} from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../store/rootReducer';
import { elementsActions } from '../store/elements/slice';
import { IAppDispatch } from '../store/store';
import { projectsActions } from '../store/projects/slice';
import { generalActions } from '../store/general/slice';
import { localGetAuthData } from './localStore';
import { authActions, authLogout } from '../store/auth/slice';

export const useAppDispatch = () => useDispatch<IAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

export const useActions = () => {
  const dispatch = useAppDispatch();
  const boundActionCreators = useMemo(
    () => bindActionCreators({
      ...generalActions,
      ...elementsActions,
      ...projectsActions,
      ...authActions,
      authLogout,
    }, dispatch),
    [dispatch],
  );

  return boundActionCreators;
};

export const useEvent = <T = unknown>(handler: T) => {
  const fnRef = useRef<typeof handler | null>(null);

  useLayoutEffect(() => {
    fnRef.current = handler;
  });

  return useCallback((...args: Parameters<((...props: unknown[]) => unknown) & typeof handler>) => {
    const fn = fnRef.current;
    return (fn as ((...props: unknown[]) => unknown))(...args) as ReturnType<
    ((...props: unknown[]) => unknown) & typeof handler
    >;
  }, []);
};

export const useInit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { authSetAuthData } = useActions();

  useEffect(() => {
    setIsLoading(true);
    localGetAuthData()
      .then((data) => {
        authSetAuthData(data);
        return !!data;
      })
      .catch(() => {
        authSetAuthData(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authSetAuthData]);

  return isLoading;
};

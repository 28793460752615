import { FC, memo, MouseEvent } from 'react';
import Img from '../../Img/Img';
import { cn, checkIsNoClickPropagation } from '../../../utils/general';
import { getHeaderTabStyle } from '../../../utils/getStyles';
import { useEvent } from '../../../utils/hooks';
import IconButton from '../../IconButton/IconButton';
import { IHeaderTabsItem } from '../interfaces/IHeaderTabsItem';
import styles from '../styles/HeaderTabsItem.module.scss';
import DotIcon from '../../../assets/dot.svg';
import ActiveDotIcon from '../../../assets/activeDot.svg';
import CloseIcon from '../../../assets/closeTab.svg';
import ActiveCloseIcon from '../../../assets/activeCloseTab.svg';

const HeaderTabsItem: FC<IHeaderTabsItem> = ({
  item, provided, snapshot, isActive, onTabClick, onClose, isDrafted,
}) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    if (checkIsNoClickPropagation(e.target as HTMLElement, e.currentTarget)) {
      onTabClick(item.id);
    }
  };

  const close = useEvent(() => {
    onClose(item.id);
  });

  return (
    <div
      ref={provided.innerRef}
      data-rbd-draggable-context-id={provided.draggableProps['data-rbd-draggable-context-id']}
      data-rbd-draggable-id={provided.draggableProps['data-rbd-draggable-id']}
      onTransitionEnd={provided.draggableProps.onTransitionEnd}
      aria-describedby={provided.dragHandleProps?.['aria-describedby']}
      data-rbd-drag-handle-context-id={provided.dragHandleProps?.['data-rbd-drag-handle-context-id']}
      data-rbd-drag-handle-draggable-id={provided.dragHandleProps?.['data-rbd-drag-handle-draggable-id']}
      draggable={provided.dragHandleProps?.draggable}
      onDragStart={provided.dragHandleProps?.onDragStart}
      role="button"
      tabIndex={provided.dragHandleProps?.tabIndex}
      style={getHeaderTabStyle(snapshot, provided.draggableProps.style)}
      className={cn(styles.container, isActive && styles.active)}
      onClick={onClick}
      onKeyDown={() => { }}
    >
      <span className={styles.name}>{item.name}</span>
      <div className={styles.statusClose}>
        {isDrafted && <Img src={isActive ? ActiveDotIcon : DotIcon} customClass={styles.dotIcon} size={6} />}
        <IconButton
          onPress={close}
          icon={isActive ? ActiveCloseIcon : CloseIcon}
          size={15}
          customClass={styles.close}
        />
      </div>
    </div>
  );
};

export default memo(HeaderTabsItem);

import { FC, memo } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { IMain } from '../interfaces/IMain';
import ProjectView from './ProjectView';
import MainMenu from './MainMenu';
import ModalElementForm from '../../../components/ModalForms/ModalElementForm';
import ModalProjectForm from '../../../components/ModalForms/ModalProjectForm';
import ModalAgreement from '../../../components/ModalAgreement/ModalAgreement';
import {
  IElementFormData, IProject, IProjectElementFormData, IProjectFormData,
} from '../../../interfaces/general';
import { useActions, useAppSelector, useEvent } from '../../../utils/hooks';
import { emptyFunc, trimValuesInObj } from '../../../utils/general';
import { getProjectsWithUpdatedProject, getTabIdAfterCloseTab } from '../../../utils/projectUtils';
import projectsApi, { useCreateProjectMutation, useDeleteProjectMutation } from '../../../apis/projectsApi/projectsApi';
import styles from '../styles/Main.module.scss';

const Main: FC<IMain> = ({
  onChangeTarget, target,
}) => {
  const { t } = useTranslation();
  const {
    activeModalProjectFormData,
    activeModalProjectElementFormData,
    modalDeleteId,
    draftedProjects,
    currentProjectId,
    projectsTabs,
    isNewProject,
  } = useAppSelector((state) => state.projects, shallowEqual);
  const {
    projectsSetActiveModalProjectFormData,
    projectsSetActiveModalProjectElementFormData,
    projectSetModalDeleteId,
    projectsSetCurrentProjectId,
    projectsSetDraftedProjects,
    projectsSetProjectsTabs,
    projectSetNewProject,
  } = useActions();
  const [createProjectApi] = useCreateProjectMutation();
  const [deleteProjectApi, { isLoading }] = useDeleteProjectMutation();

  const draftedProject = draftedProjects.find((item) => item.id === currentProjectId);
  const { data } = projectsApi.endpoints.getProjectDetails
    .useQueryState(currentProjectId || 0);
  const projectDetails = draftedProject || data;

  const closeModals = useEvent(() => {
    projectSetNewProject(false);
    projectsSetActiveModalProjectFormData(null);
    projectsSetActiveModalProjectElementFormData(null);
  });

  const closeModalErrorAlert = useEvent(() => {
    projectSetModalDeleteId('');
  });

  const deleteProject = useEvent(() => {
    const deleteId = modalDeleteId;
    if (typeof deleteId === 'string' && projectDetails) {
      const updatedDraftedProjects = getProjectsWithUpdatedProject(
        draftedProjects,
        {
          ...projectDetails,
          elements: projectDetails.elements
            .filter((item) => item.uid !== deleteId && item.parentElementUid !== deleteId),
        },
      );
      projectsSetDraftedProjects(updatedDraftedProjects);
      closeModalErrorAlert();
    } else {
      deleteProjectApi(modalDeleteId).unwrap()
        .then(() => {
          const nextProjectId = getTabIdAfterCloseTab(deleteId as number, currentProjectId, projectsTabs);
          projectsSetCurrentProjectId(nextProjectId);
          if (projectsTabs.some((item) => item.id === deleteId)) {
            projectsSetProjectsTabs(projectsTabs.filter((item) => item.id !== deleteId));
          }
          if (draftedProjects.some((item) => item.id === deleteId)) {
            projectsSetDraftedProjects(draftedProjects.filter((item) => item.id !== deleteId));
          }
          projectSetModalDeleteId('');
        })
        .catch(emptyFunc);
    }
  });

  const createProject = useEvent((values: IProjectFormData, { setSubmitting }: FormikHelpers<IProjectFormData>) => {
    const projectData = trimValuesInObj<IProject>({
      name: values.name,
      title: values.title,
      elementsData: [],
    });
    createProjectApi(projectData).unwrap()
      .then((res) => {
        setSubmitting(false);
        projectsSetCurrentProjectId(res.id);
        projectsSetProjectsTabs([...projectsTabs, { id: res.id, name: res.name }]);
        closeModals();
      })
      .catch(() => {
        setSubmitting(false);
      });
  });

  const editProject = useEvent((values: IProjectFormData, { setSubmitting }: FormikHelpers<IProjectFormData>) => {
    if (projectDetails) {
      const updatedProject = {
        ...projectDetails,
        name: values.name,
        title: values.title,
      };
      projectsSetDraftedProjects(getProjectsWithUpdatedProject(draftedProjects, updatedProject));
      setSubmitting(false);
      closeModals();
    }
  });

  const editProjectElement = useEvent((formData: IElementFormData | IProjectElementFormData) => {
    const updatedDraftedProjects = getProjectsWithUpdatedProject(
      draftedProjects,
      {
        ...projectDetails as IProject,
        elements: (projectDetails as IProject).elements.map((item) => {
          if (item.uid === activeModalProjectElementFormData?.uid) {
            return {
              ...item,
              ...formData as IProjectElementFormData,
              value: parseFloat((formData as IProjectElementFormData).value),
            };
          }
          return item;
        }),
      },
    );
    projectsSetDraftedProjects(updatedDraftedProjects);
    projectsSetActiveModalProjectElementFormData(null);
  });

  return (
    <div className={styles.container}>
      {currentProjectId ? (
        <ProjectView onChangeTarget={onChangeTarget} target={target} />
      ) : (
        <MainMenu />
      )}
      <ModalAgreement
        title={t(typeof modalDeleteId === 'string'
          ? 'wantDeleteParentProjectElement'
          : 'wantDeleteProject')}
        isVisible={!!modalDeleteId}
        onClose={closeModalErrorAlert}
        onAgree={deleteProject}
        isLoading={isLoading}
      />
      <ModalElementForm
        title={t('editElement')}
        isVisible={!!activeModalProjectElementFormData}
        onClose={closeModals}
        onSubmit={editProjectElement}
        isProjectElement
        multiplier={projectDetails?.multiplier}
        initValues={activeModalProjectElementFormData}
      />
      <ModalProjectForm
        title={t(activeModalProjectFormData ? 'editProject' : 'createProject')}
        isVisible={!!activeModalProjectFormData || isNewProject}
        onClose={closeModals}
        onSubmit={activeModalProjectFormData ? editProject : createProject}
        initValues={activeModalProjectFormData}
      />
    </div>
  );
};

export default memo(Main);

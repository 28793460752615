import { createSlice } from '@reduxjs/toolkit';
import { IElementsState } from './interfaces/IElementsState';
import {
  IAction, IElement, IElementFormData, IFolderPathItem, IFolderFormData,
} from '../../interfaces/general';

export const initialState: IElementsState = {
  folderPath: [],
  cutOutElement: null,
  modalDeleteId: null,
  activeModalFormsData: null,
  isNewElementsItem: false,
  isNewElementsFolder: false,
};

export const elements = createSlice({
  name: 'elements' as const,
  initialState,
  reducers: {
    elementsSetFolderPath: (state, { payload }: IAction<IFolderPathItem[]>) => {
      state.folderPath = payload;
    },
    elementsSetCutOutElement: (state, { payload }: IAction<IElement | null>) => {
      state.cutOutElement = payload;
    },
    elementsSetModalDeleteId: (state, { payload }: IAction<number | null>) => {
      state.modalDeleteId = payload;
    },
    elementsSetActiveModalFormData: (state, { payload }: IAction<IElementFormData | IFolderFormData | null>) => {
      state.activeModalFormsData = payload;
    },
    elementsSetNewElementsItem: (state, { payload }: IAction<boolean>) => {
      state.isNewElementsItem = payload;
    },
    elementsSetNewElementsFolder: (state, { payload }: IAction<boolean>) => {
      state.isNewElementsFolder = payload;
    },
  },
});

export const elementsActions = elements.actions;

export default elements.reducer;

import { useFormik } from 'formik';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { projectFormValidation } from '../../../utils/validations';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import { IProjectForm } from '../interfaces/IProjectForm';
import styles from '../styles/ElementForm.module.scss';

const ProjectForm: FC<IProjectForm> = ({ onSubmit, initValues }) => {
  const { t } = useTranslation();
  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: initValues || {
      name: '',
      title: '',
    },
    onSubmit,
    validationSchema: projectFormValidation,
  });

  return (
    <div className={styles.container}>
      <Input
        value={values.name}
        title={t('name')}
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
        error={touched.name && errors.name}
      />
      <Input
        value={values.title}
        title={t('title')}
        onChange={handleChange('title')}
        onBlur={handleBlur('title')}
        error={touched.title && errors.title}
      />
      <div className={styles.footer}>
        <Button
          title={t(initValues ? 'save' : 'create')}
          disabled={!isValid || isSubmitting || !dirty}
          onPress={handleSubmit}
        />
      </div>
    </div>
  );
};

export default memo(ProjectForm);

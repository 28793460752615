import { FC, memo, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ProjectElementsItem from './components/ProjectElementsItem';
import { IProjectElements } from './interfaces/IProjectElements';
import { getProjectElementsListStyle } from '../../utils/getStyles';
import { DataPrefix, ProjectViewTargets } from '../../constants/defines';
import styles from './styles/ProjectElements.module.scss';
import { getDragDropUId } from '../../utils/projectUtils';

const ProjectElements: FC<IProjectElements> = ({
  data, onChangeTarget, type, onChangeOrder, menuAction, multiplier, isMenuVisible,
}) => {
  const filteredData = useMemo(() => (
    data.filter((item) => !item.parentElementUid)
  ), [data]);

  return (
    <Droppable
      droppableId={getDragDropUId(DataPrefix.PROJECT)}
      ignoreContainerClipping={false}
      isCombineEnabled={type !== ProjectViewTargets.PROJECT}
      type={type}
    >
      {(droppableProvided, droppableSnapshot) => (
        <div
          className={styles.container}
          ref={droppableProvided.innerRef}
          style={getProjectElementsListStyle(droppableSnapshot)}
          data-rbd-droppable-context-id={droppableProvided.droppableProps['data-rbd-droppable-context-id']}
          data-rbd-droppable-id={droppableProvided.droppableProps['data-rbd-droppable-id']}
        >
          {filteredData.map((item, index) => (
            <Draggable
              key={item.uid}
              draggableId={item.uid}
              index={index}
            >
              {(provided, snapshot) => (
                <ProjectElementsItem
                  provided={provided}
                  snapshot={snapshot}
                  data={item}
                  onChangeTarget={onChangeTarget}
                  index={index}
                  childData={data.filter((childElement) => childElement.parentElementUid === item.uid)}
                  onChangeOrder={onChangeOrder}
                  menuAction={menuAction}
                  multiplier={multiplier}
                  isMenuVisible={isMenuVisible}
                />
              )}
            </Draggable>
          ))}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default memo(ProjectElements);

import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IWindowTitleBar } from './interfaces/IWindowTitleBar';
import styles from './styles/WindowTitleBar.module.scss';

const WindowTitleBar: FC<IWindowTitleBar> = ({
  title,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span>{`${t('projectEstimation')}${title ? `: ${title}` : ''}`}</span>
      </div>
    </div>
  );
};

export default memo(WindowTitleBar);

import {
  IImgSrc, IIconSet,
} from '../interfaces/general';
import { ImgStates } from '../constants/defines';

export const getIconSet = (
  icon?: IImgSrc,
  iconHover?: IImgSrc,
  iconActive?: IImgSrc,
  iconDisabled?: IImgSrc,
  customIconSet?: IIconSet,
): IIconSet => {
  const Icon = customIconSet?.Icon 
  || icon 
  || iconHover 
  || iconActive 
  || iconDisabled 
  || customIconSet?.IconHover 
  || customIconSet?.IconActive 
  || customIconSet?.IconDisabled;
  return {
    Icon,
    IconHover: customIconSet?.IconHover || iconHover || Icon,
    IconActive: customIconSet?.IconActive || iconActive || iconHover || Icon,
    IconDisabled: customIconSet?.IconDisabled || iconDisabled || Icon,
  };
};

export const getIconFromIconSetByState = (state: ImgStates, iconSet: IIconSet) => {
  switch (state) {
    case ImgStates.IDLE:
      return iconSet.Icon;
    case ImgStates.HOVER:
      return iconSet.IconHover;
    case ImgStates.ACTIVE:
      return iconSet.IconActive;
    case ImgStates.DISABLED:
      return iconSet.IconDisabled;
    default:
      return iconSet.Icon;
  }
};

import { FC, memo } from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import AppView from '../pages/App/AppView';
import SignInView from '../pages/SignIn/SignInView';
import SignUpView from '../pages/SignUp/SignUpView';
import SendConfirmationEmailView from '../pages/SendConfirmationEmail/SendConfirmationEmailView';
import { PathRoutes } from '../constants/defines';
import AuthRoute from './components/AuthRoute';
import ForgotPasswordView from '../pages/ForgotPassword/ForgotPasswordView';
import NoAuthRoute from './components/NoAuthRoute';
import ResetPasswordView from '../pages/ResetPassword/ResetPasswordView';

const getAuthRoute = (Component: FC) => (
  <AuthRoute>
    <Component />
  </AuthRoute>
);

const getNoAuthRoute = (Component: FC) => (
  <NoAuthRoute>
    <Component />
  </NoAuthRoute>
);

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={getAuthRoute(AppView)} />
        <Route path={PathRoutes.SEND_EMAIL_CONFIRMATION} element={getNoAuthRoute(SendConfirmationEmailView)} />
        <Route path={PathRoutes.SIGNIN} element={getNoAuthRoute(SignInView)} />
        <Route path={PathRoutes.SIGNUP} element={getNoAuthRoute(SignUpView)} />
        <Route path={PathRoutes.FORGOT_PASSWORD} element={getNoAuthRoute(ForgotPasswordView)} />
        <Route path={PathRoutes.RESET_PASSWORD} element={getNoAuthRoute(ResetPasswordView)} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default memo(AppRouter);

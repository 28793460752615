import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import Img from '../Img/Img';
import Loader from '../Loader/Loader';
import { TITLE } from '../../constants/general';
import TextButton from '../TextButton/TextButton';
import { IAuthForm } from './interfaces/IAuthFrom';
import { cn, emptyFunc } from '../../utils/general';
import styles from './styles/AuthForm.module.scss';

const AuthForm: FC<IAuthForm> = ({
  children, footerLinks, title, message, isSuccessMessage, isLoading,
}) => (
  <div className={styles.form}>
    <div className={styles.formHeader}>
      <div className={styles.logo}>
        <Img src="/logo192.png" size={60} />
        <span>{TITLE}</span>
      </div>
      {title && (
        <span className={styles.title}>
          {title}
        </span>
      )}
    </div>

    <div className={styles.formContent}>
      {children}
      <div className={styles.infoContainer}>
        {isLoading ? (
          <Loader size={50} />
        ) : (
          <p
            className={cn(
              styles.message,
              isSuccessMessage ? styles.successMessage : styles.errorMessage,
            )}
          >
            {message || ''}
          </p>
        )}
      </div>

    </div>

    {footerLinks && !!footerLinks.length && (
      <div className={styles.formFooter}>
        {footerLinks.map((item) => (
          <NavLink key={`${item.title}-${item.route}`} to={item.route}>
            <TextButton title={item.title} onPress={emptyFunc} />
          </NavLink>
        ))}
      </div>
    )}
  </div>
);

export default memo(AuthForm);

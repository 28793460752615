import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../constants/general';
import { ReqMethods } from '../../constants/defines';
import {
  IForgotPasswordFormData, IResetPasswordFormData, ISendEmailConfirmationFormData, ISignInFormData, ISignUpFormData,
} from '../../interfaces/formsData';
import { IAuthData, ISimpleResponse } from '../../interfaces/responses';
import { localRemoveAuthData, localSaveAuthData } from '../../utils/localStore';
import { emptyFunc } from '../../utils/general';
import { customBaseQuery } from '../../utils/request';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customBaseQuery({
    baseUrl: `${API_URL}/auth`,
  }),
  endpoints: (builder) => ({
    signIn: builder.mutation<IAuthData, ISignInFormData>({
      query: (body) => ({
        url: '/local',
        method: ReqMethods.POST,
        body: {
          identifier: body.email.trim(),
          password: body.password,
        },
      }),
      transformResponse: async (result) => {
        await localSaveAuthData(result as IAuthData).catch(emptyFunc);
        return result as IAuthData;
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled.then(async (data) => {
          await localSaveAuthData(data.data).catch(emptyFunc);
        })
          .catch(async () => {
            await localRemoveAuthData().catch(emptyFunc);
          });
      },
    }),
    signUp: builder.mutation<IAuthData, ISignUpFormData>({
      query: (body) => ({
        url: '/local/register',
        method: ReqMethods.POST,
        body: {
          email: body.email.trim(),
          username: body.email.trim(),
          firstName: body.firstName.trim(),
          lastName: body.lastName.trim(),
          password: body.password,
        },
      }),
    }),
    forgotPassword: builder.mutation<ISimpleResponse, IForgotPasswordFormData>({
      query: (body) => ({
        url: '/forgot-password',
        method: ReqMethods.POST,
        body: {
          email: body.email.trim(),
        },
      }),
    }),
    resetPassword: builder.mutation<ISimpleResponse, IResetPasswordFormData>({
      query: (body) => ({
        url: '/reset-password',
        method: ReqMethods.POST,
        body: {
          code: body.code,
          password: body.password,
          passwordConfirmation: body.passwordConfirmation,
        },
      }),
    }),
    sendEmailConfirmation: builder.mutation<ISimpleResponse, ISendEmailConfirmationFormData>({
      query: (body) => ({
        url: '/send-email-confirmation',
        method: ReqMethods.POST,
        body: {
          email: body.email.trim(),
        },
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSendEmailConfirmationMutation,
} = authApi;

export default authApi;
